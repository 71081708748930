const ProductStatus = [
  {
    id: 0,
    name: "Fuori Catalogo",
    key: "OUT_CATALOGUE",
  },
  {
    id: 1,
    name: "In Catalogo",
    key: "IN_CATALOGUE",
  },
  {
    id: 2,
    name: "Non disponibile",
    key: "NOT_AVAILABLE",
  },
  {
    id: 3,
    name: "Da Completare",
    key: "TO_COMPLETE",
  },
  {
    id: 4,
    name: "Campione",
    key: "SAMPLE",
  },
  {
    id: 5,
    name: "Prevendita",
    key: "PRESALE",
  }
];

export { ProductStatus };