import React from "react";
import Link from "next/link";
import { useAuth } from "components/auth/hooks/useAuth";
import { useTranslation } from "next-i18next";
// BOOTSTRAP
import { Nav } from "react-bootstrap";
// ICONS
import { LogOutIcon } from "@/public/icons/icons";

export default function ServicesLinksMobile({ setExpanded }) {
  const auth = useAuth();
  const { t } = useTranslation("header");

  // Logs out the user (availbale only if auth.user is present)
  const signOutUser = () => {
    return auth.signOut();
  };

  return (
    <Nav className={`services align-items-start align-items-lg-center`}>
      <Link href="/customer-service" passHref>
        <Nav.Link className="py-3 ps-4  fw-medium pt-3">{t("customer-service")}</Nav.Link>
      </Link>
      <Link href="/contact" passHref>
        <Nav.Link className="py-3 ps-4 fw-medium">{t("contact")}</Nav.Link>
      </Link>
      <div className="d-lg-none">
        <Link href="/about-us" passHref>
          <Nav.Link className="py-3 ps-4 fw-medium" onClick={() => setExpanded(false)}>
            {t("about-us")}
          </Nav.Link>
        </Link>
        <Link href="/find-us" passHref>
          <Nav.Link className="py-3 ps-4 fw-medium" onClick={() => setExpanded(false)}>
            {t("find-us")}
          </Nav.Link>
        </Link>
        <Link href="/catalogue/NEW_PRODUCTS?Page=1" passHref>
          <Nav.Link className="py-3 ps-4 fw-medium">{t("new-arrivals")}</Nav.Link>
        </Link>
        {auth.user ? (
          <Nav.Link
            className="py-3 ps-4  fw-medium"
            onClick={() => {
              signOutUser();
              setExpanded(false);
            }}
          >
            <span className="pe-1">
              <LogOutIcon width="16" height="16" strokeWidth="2" />{" "}
            </span>
            {t("logout")}
          </Nav.Link>
        ) : null}
      </div>
    </Nav>
  );
}
