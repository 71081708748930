import React from "react";
import ContentLoader from "react-content-loader";

const SkeletonDropdownCart = (props) => (
  <ContentLoader
    speed={2}
    width={250}
    height={360}
    viewBox="0 0 250 360"
    backgroundColor="#e8e8e8"
    foregroundColor="#d1d1d1"
    {...props}
  >
    <rect x="0" y="16" rx="2" ry="2" width="92" height="143" />
    <rect x="110" y="19" rx="2" ry="2" width="54" height="16" />
    <rect x="110" y="65" rx="2" ry="2" width="132" height="16" />
    <rect x="110" y="41" rx="2" ry="2" width="132" height="16" />
    <rect x="110" y="95" rx="2" ry="2" width="54" height="16" />
    <rect x="110" y="141" rx="2" ry="2" width="132" height="16" />
    <rect x="110" y="117" rx="2" ry="2" width="132" height="16" />
    <rect x="0" y="218" rx="2" ry="2" width="239" height="16" />
    <rect x="0" y="194" rx="2" ry="2" width="239" height="16" />
    <rect x="0" y="265" rx="2" ry="2" width="239" height="16" />
    <rect x="0" y="241" rx="2" ry="2" width="239" height="16" />
    <rect x="0" y="173" rx="0" ry="0" width="240" height="0" />
    <rect x="0" y="314" rx="2" ry="2" width="239" height="29" />
  </ContentLoader>
)

export default SkeletonDropdownCart
