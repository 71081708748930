/**
 * @type {import('next-i18next').UserConfig}
 */

module.exports = {
  i18n: {
    // https://www.i18next.com/overview/configuration-options#logging
    // debug:  process.env.NEXT_PUBLIC_ENV == undefined,
    debug: false,
    defaultLocale: "it",
    fallbackLng: "en",
    locales: ["it", "en", "fr"],
    /** To avoid issues when deploying to some paas (vercel...) */
    localePath: typeof window === "undefined" ? require("path").resolve("./public/locales") : "/locales",
    // This option will reload your translations whenever serverSideTranslations is called (in getStaticProps or getServerSideProps)
    reloadOnPrerender: process.env.NEXT_PUBLIC_ENV == undefined,
  },
};
