import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { useTranslation } from "next-i18next";
// LAYOUT
import { useAuth } from "@/components/auth/hooks/useAuth";
import { getAccountExpiration } from "@/common/utils/sharedFunctions";
// DYNAMIC IMPORTS
import Header from "@/components/core/Header/Header";
import NextJsBreadcrumbs from "@/components/core/Breadcrumb/Breadcrumb";
const Footer = dynamic(() => import("@/components/core/Footer/Footer"));
const TopBanner = dynamic(() => import("@/components/elements/TopBanner/TopBanner"), { ssr: false });
const UnderMaintenance = dynamic(() => import("@/components/core/UnderMaintenance/UnderMaintenance"), { ssr: false });
// STYLE
import s from "./Layout.module.scss";
import { useModal } from "@/components/context/ModalContext";
import CustomModal from "@/components/portals/CustomModal/CustomModal";
import detectIE from "@/common/utils/AppFunctions/detectIE";
import CookieBanner from "@/components/elements/CookieBanner/CookieBanner";

function Layout({ children }) {
  const router = useRouter();
  const auth = useAuth();
  const { t } = useTranslation("common");

  // this is the top banner text to be displayed (news, sale, greatings, etc.)
  const topBannerDefault = { text: "", title: "", color: "white" };
  const [topBannerNews, seTopBannerNews] = useState(topBannerDefault);
  useEffect(() => {
    // When user is still not active, we need the expiring remaining day
    if (auth.user?.StatoLogin != "ACTIVE")
      getAccountExpiration(auth.user).then((exp) => {
        if (exp?.expiring > 0) {
          if (auth.user?.StatoLogin == "TEMPORARY")
          seTopBannerNews({
            text: t("expiring-banner.message"),
            title: t("expiring-banner.expiring-in") + ' '+ exp?.expiring + ' ' + t("expiring-banner.days"),
            color: "sky-blue",
          });
          else if (auth.user?.StatoLogin == "LOGIN_CHECK")
            seTopBannerNews({
              text: t("disabling-banner.message"),
              title: t("disabling-banner.disabling-in") + ' '+ exp?.expiring + ' ' + t("disabling-banner.days"),
              color: "orange",
            });
        } else {
          seTopBannerNews(topBannerDefault);
        }
      });
  }, [auth.user]);

  // Block users with Internet Explorer v6 - v11 with a popup.
  const { showModal } = useModal();

  const showAlertModal = () =>
    showModal(CustomModal, {
      backdrop: "static",
      closeFunction: () => router.back(),
      title: t("modals.attention"),
      message: t("modals.browser-not-supported"),
    });

  useEffect(() => {
    detectIE() && showAlertModal();
  }, []);

  // Base on router pathname will hide header, footer and breadcrumb.
  const showTopBanner =
    router.pathname === "/mobile" ||
    router.pathname === "/checkout-s" ||
    router.pathname === "/checkout-c" ||
    router.pathname === "/admin-checkout-c" ||
    router.pathname === "/admin-checkout-s" ||
    router.pathname === "/home-supplier" ||
    router.pathname === "/checkout-e"
      ? false
      : true;
  const showHeader =
    router.pathname === "/mobile" ||
    router.pathname === "/checkout-s" ||
    router.pathname === "/checkout-c" ||
    router.pathname === "/admin-checkout-c" ||
    router.pathname === "/admin-checkout-s" ||
    router.pathname === "/home-supplier" ||
    router.pathname === "/checkout-e"
      ? false
      : true;
  const showBreadcrumb =
    router.pathname === "/mobile" ||
    router.pathname === "/" ||
    router.pathname === "/checkout-s" ||
    router.pathname === "/checkout-c" ||
    router.pathname === "/admin-checkout-c" ||
    router.pathname === "/admin-checkout-s" ||
    router.pathname === "/home-supplier" ||
    router.pathname === "/checkout-e" ||
    router.pathname === "/catalogue/[category]/[product]" ||
    router.pathname === "/reservations/[category]/[product]" ||
    router.pathname === "/presales/[category]/[product]"
      ? false
      : true;
  const showFooter =
    router.pathname === "/mobile" ||
    router.pathname === "/checkout-s" ||
    router.pathname === "/checkout-c" ||
    router.pathname === "/admin-checkout-c" ||
    router.pathname === "/admin-checkout-s" ||
    router.pathname === "/home-supplier" ||
    router.pathname === "/checkout-e"
      ? false
      : true;

  return !auth.isUserAdmin() && process.env.NEXT_PUBLIC_MAINTENANCE == "true" && router.pathname != "/mobile" ? (
    <UnderMaintenance />
  ) : (
    <>
      <div className={s.body}>
        {showTopBanner && <TopBanner text={topBannerNews.text} title={topBannerNews.title} color={topBannerNews.color} />}
        {!auth.isUserSupplier() && showHeader && <Header />}
        {!auth.isUserSupplier() && showBreadcrumb && <NextJsBreadcrumbs />}
        {children}
      </div>
      {!auth.isUserSupplier() && showFooter && <Footer />}
      {!auth.isUserAdmin() && <CookieBanner />}
    </>
  );
}

export default Layout;
