export const gruppomerceologicoabbigliamento = [
  {
    id: 0,
    name: "Abiti",
    key: "CLOTHES",
  },
  {
    id: 1,
    name: "Accessori",
    key: "ACCESSORIES",
  },
  {
    id: 2,
    name: "Bermuda",
    key: "BERMUDA",
  },
  {
    id: 3,
    name: "Camicie",
    key: "SHIRTS",
  },
  {
    id: 4,
    name: "Canotte",
    key: "TANK-TOPS",
  },
  {
    id: 5,
    name: "Cappotti",
    key: "COATS",
  },
  {
    id: 6,
    name: "Felpe",
    key: "SWEATSHIRTS",
  },
  {
    id: 7,
    name: "Giacche",
    key: "JACKETS",
  },
  {
    id: 8,
    name: "Gilet",
    key: "VESTS",
  },
  {
    id: 9,
    name: "Gonne",
    key: "SKIRTS",
  },
  {
    id: 10,
    name: "Jeans",
    key: "JEANS",
  },
  {
    id: 11,
    name: "Leggings",
    key: "LEGGINGS",
  },
  {
    id: 12,
    name: "Maglieria",
    key: "KNITWEAR",
  },
  {
    id: 13,
    name: "Magliette",
    key: "SLEEVED-TSHIRTS",
  },
  {
    id: 14,
    name: "Pantaloni",
    key: "TROUSERS",
  },
  {
    id: 15,
    name: "Piumini",
    key: "DUVETS",
  },
  {
    id: 16,
    name: "Salopette",
    key: "DUNGAREES",
  },
  {
    id: 17,
    name: "T-Shirts",
    key: "TSHIRTS",
  },
  {
    id: 18,
    name: "Tute",
    key: "SUITS",
  },
  {
    id: 19,
    name: "Borse",
    key: "BAGS",
  },
  // {
  //   id: 20,
  //   name: "Special Price",
  //   key: "DISCOUNT_PRODUCTS"
  // },
  {
    id: 21,
    name: "Coordinati e Tailleur",
    key: "COORDINATED-TAILLEUR",
  },
  {
    id: 22,
    name: "Copricostumi",
    key: "COVER-UPS"
  },
];

export const gruppomerceologicodpi = [
  {
    id: 0,
    name: "Igienizzanti",
    key: "SANITIZERS"
  },
  {
    id: 1,
    name: "Mascherine",
    key: "MASKS"
  },
];

export const settoreMerceologico = [
  {
    id: 0,
    name: "Abbigliamento",
    key: "CLOTHES",
    IVA: 22,
    cod_iva: 0,
  },
  {
    id: 1,
    name: "DPI",
    key: "DPI",
    IVA: 4,
    cod_iva: 4,
  },
];

export const ProductGroupClasses = {
  0: gruppomerceologicoabbigliamento,
  1: gruppomerceologicodpi,
  settoreMerceologico,
};
