const OrderStatus = [
  // These status are used only on presales
  { id: "-2", name: "Prevendita", key: "PRESALE", color: "VIOLET" },
  { id: "-1", name: "In produzione", key: "PRODUCING", color: "LILAC" },
  // These are standard order status 
  { id: "0", name: "In elaborazione", key: "PROCESSING", color: "YELLOW" },
  { id: "1", name: "In spedizione", key: "DELIVERY_READY", color: "SKY_BLUE" },
  { id: "2", name: "Spedito", key: "SHIPPED", color: "GREEN" },
  { id: "3", name: "Fatturato", key: "INVOICE_CREATED", color: "BLUE" },
  { id: "4", name: "Cancellato", key: "CANCELLED", color: "RED" },
  { id: "5", name: "Errore", key: "ERROR", color: "RED" },
  { id: "6", name: "Pronto per il ritiro", key: "PICKUP_READY", color: "GREEN" },
];


class OrderQuantityStatus {
  public static readonly PARTIAL = { id: "0", name: "Parziale", color: "" };
  public static readonly COMPLETE = { id: "1", name: "Completo", color: "" };
  public static readonly PARTIAL_TO_REFUND = { id: "2", name: "Da rimborsare", color: "YELLOW" };
  public static readonly PARTIAL_NEW_ORDER = { id: "3", name: "Parziale nuovo ordine", color: "" };
  public static readonly PARTIAL_REFUNDED = { id: "4", name: "Rimborso emesso", color: "GREEN" };
  public static readonly ERROR = { id: "5", name: "Errore", color: "RED" };
  public static readonly PROCESSING_PARTIAL = { id: "6", name: "Parziale", color: "" };
}

export { OrderStatus, OrderQuantityStatus };
