import { fb_auth } from "config/firebase";
import { useState, useEffect, useContext, createContext, ReactNode } from "react";
import { initFavouriteList, useSetFavourites } from "components/context/FavouritesContext/FavouritesContext";
import { fetcher } from "@/pages/api/util/fetcher";
import { checkVAT, countries } from "jsvat";
import { authError } from "@/components/auth/error/auth";
import { applyActionCode, confirmPasswordReset, createUserWithEmailAndPassword, onAuthStateChanged, sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword, User } from "firebase/auth";
import { truncate } from "fs/promises";

const authContext = createContext({});
const { Provider } = authContext;

export function AuthProvider(props: { children: ReactNode }): JSX.Element {
  const auth = useAuthProvider();

  return <Provider value={auth}>{props.children}</Provider>;
}

/**
 * Create the authentication context
 * @returns the auth context
 */
export const useAuth: any = () => {
  return useContext(authContext);
};

/**
 * Provider hook that creates an auth object and handles it's state
 * @returns  the auth provider
 */
const useAuthProvider = () => {
  const [user, setUser] = useState(null);
  // const [FBuser, setFBUser] = useState(null);
  const [isLoading, setisLoading] = useState(true);
  const setFavourites = useSetFavourites();

  // Creates the user on mondoDB
  const createUser = async (userData) => {
    return await fetcher("/api/auth/signUp", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...userData }),
    })
      .catch((error) => {
        throw Error(error);
      })
      .then((response) => {
        if (response.error) throw Error(response.error);
        else return response;
      });
  };

  /**
   * User is logged in, updates the login dates and set the state with the new values
   * @param authUser firebase:user
   */
  const handleAuthStateChanged = (authUser: User) => {
    // setFBUser(authUser);
    if (authUser && authUser.emailVerified) {
      updateLoginUserInformation(authUser).then((status) => {
        if (status.error) {
          // User is not logged in
          signOut();
          setisLoading(false);
        }
        else {
          localStorage.setItem("user", JSON.stringify(status));
          return status;
        }

      });
    } else {
      // User is not logged in
      signOut();
      setisLoading(false);
    }
  };

  useEffect(() => {
    let localUser = localStorage.getItem("user");

    if (localUser) {
      // Initializes user locally
      initLocalUser(localUser);
      // admin.auth().verifyIdToken(idToken)
    }
    const unsub = onAuthStateChanged(fb_auth, handleAuthStateChanged);
    return () => unsub();
  }, []);

  /**
   * Set localstorage the current user and init some useful
   * @param localUser
   */
  const initLocalUser = (localUser) => {
    if (localUser) {
      //
      const usr = typeof localUser === "object" ? localUser : JSON.parse(localUser);

      // Initializes the favourite list
      initFavouriteList().then((favs) => {
        setFavourites(favs);
      });

      setUser(usr);
      setisLoading(false);
    }
  };

  /**
   * Check and updates login status on BE and retrieve useful information (i.e. favourite list)
   * @param authUser firebase.User
   * @returns updateLoginUser informations
   */
  const updateLoginUserInformation = async (authUser: User) => {
    const token = await authUser.getIdToken();

    return fetcher("/api/auth/expiration", {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        authId: authUser.uid,
        sessionToken: token,
      }),
    }).then((response) => {
      if (!response["error"]) {
        switch (response["updateLoginUser"].StatoLogin) {
          case "TEMPORARY":
            break;
          case "ACTIVE":
            break;
          case "EXPIRED":
            return authError(response, "auth/user-expired");
            break;
          case "DISABLED":
            return authError(response, "auth/user-disabled");
            break;
          case "LOGIN_CHECK":
            break;
        }
        // Initializes the favourite list
        initLocalUser(response["updateLoginUser"]);
      } else {
        return authError(response, response["error"]);
      }

      return response["updateLoginUser"];
    }).catch((err) => {
      signOut();
    }
    )
  };

  const localUser = () => {
    return typeof window !== "undefined" ? localStorage.getItem("user") : null;
  };

  /**
   * Signin thorugh email and password
   * @param { email, password }
   * @returns updateLoginUserInformation
   */
  const signIn = ({ email, password }) => {
    return signInWithEmailAndPassword(fb_auth, email, password)
      .then(async (response) => {
        if (response.user.emailVerified) {
          // TODO: this is made only to retrieve user data, but unnecessary here
          return updateLoginUserInformation(response.user).then((status) => {
            return status;
          });
        } else {
          return authError(response, "auth/user-not-verified");
        }
      })
      .catch((error) => {
        return { error };
      });
  };

  /**
   * User signOut
   * @returns signed out user
   */
  const signOut = () => {
    return fb_auth
      .signOut()
      .then(async function () {
        setUser(false);
        await fetcher("/api/auth/signOut", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        localStorage.clear();
      })
      .catch(function (error) {
        return { error };
      });
  };

  /**
   * invitation sign up method
   * @param { piva, email, password }
   * @returns
   */
  const invitation_signUp = async ({ PartitaIVA, userEmail, userPassword, Nazione, lang }) => {
    // Only supported nations
    if (
      countries
        .map((ct) => ct.codes)
        .flat()
        .includes(Nazione)
    ) {
      // Checks if vat is formally correct
      let validation = {};
      if (!checkVAT(Nazione + PartitaIVA, countries).isValid) {
        return (validation = authError(validation, "Partita IVA non corretta"));
      }
    }

    const filter: any = { email: userEmail };
    return await fetcher("/api/user/user" + "?" + new URLSearchParams(filter).toString()).then((hasActivationPending) => {
      // partitaIVA must be equal to the user with pending invitation
      if (hasActivationPending.PartitaIVA == PartitaIVA && (!hasActivationPending.AuthId || hasActivationPending.AuthId == "")) {
        return createUserWithEmailAndPassword(fb_auth, userEmail, userPassword)
          .then((response) => {
            fb_auth.languageCode = lang;
            sendEmailVerification(fb_auth.currentUser);
            // set firebase role
            // admin.auth().setCustomUserClaims(user.uid, { role: "CUSTOMER" });
            return createUser({
              userData: {
                authId: response.user.uid,
                email: response.user.email,
                partitaiva: PartitaIVA,
                nazione: Nazione,
              },
              userEmail: response.user.email,
            }).catch((error) => {
              // Rollback delete fb user
              response.user.delete();
              let res = {};
              return authError(res, error.code || error.message);
            });
          })
          .catch((error) => {
            let res = {};
            return authError(res, error.code || error.message);
          });
      } else {
        let res = {};
        return authError(res, "auth/invitation-wrong-action");
      }
    });
  };

  /**
   * User registration
   * @param { piva, email, password }
   * @returns  created user
   */
  const signUp = async ({ PartitaIVA, userEmail, userPassword, Nazione, lang }) => {
    const filter = { partitaiva: PartitaIVA };
    return await fetcher("/api/user/piva" + "?" + new URLSearchParams(filter).toString()).then((isPivaPresent) => {

      // always allowed on 00000000000
      if (!isPivaPresent || PartitaIVA == "00000000000") {
        // Checks if vat is formally correct
        let validation = {};
        // Only supported nations
        if (
          countries
            .map((ct) => ct.codes)
            .flat()
            .includes(Nazione)
        ) {
          if (PartitaIVA != "00000000000" && !checkVAT(Nazione + PartitaIVA, countries).isValid) {
            // TODO: Must translate this error.
            return (validation = authError(validation, "Partita IVA non corretta"));
          }
        }

        return createUserWithEmailAndPassword(fb_auth, userEmail, userPassword)
          .then((fbUser) => {
            return createUser({
              userData: {
                authId: fbUser.user.uid,
                email: fbUser.user.email,
                partitaiva: PartitaIVA,
                nazione: Nazione,
              },
            })
              .catch((error) => {
                // Rollback delete fb user
                fbUser.user.delete();

                let res = {};
                return authError(res, error.message);
              })
              .then((response) => {
                if (response.error) return response;
                else {
                  fb_auth.languageCode = lang;
                  // Sends verification email if no previouse errors were raised
                  sendEmailVerification(fbUser.user);
                  return response;
                }
              });
          })
          .catch((error) => {
            let response = {};
            return authError(response, error.code || error.message);
          });
      } else {
        let response = {};
        return authError(response, "auth/piva-already-present");
      }
    });
  };

  /**
   * Codes verify user
   * @param code
   * @returns true or error
   */
  const verifyUser = (code, lang) => {
    fb_auth.languageCode = lang;
    return applyActionCode(fb_auth, code)
      .then((response) => {
        return true;
      })
      .catch(function (error) {
        return { error };
      });
  };

  /**
   * Emails send password reset email
   * @param email
   * @returns
   */
  const fb_sendPasswordResetEmail = (email, lang) => {
    fb_auth.languageCode = lang;
    return sendPasswordResetEmail(fb_auth, email)
      .then((response) => {
        return true;
      })
      .catch(function (error) {
        return { error };
      });
  };

  /**
   * Codes confirm password reset
   * @param code
   * @param password
   * @returns
   */
  const fb_confirmPasswordReset = (code, password) => {
    return confirmPasswordReset(fb_auth, code, password)
      .then((response) => {
        return true;
      })
      .catch(function (error) {
        return { error };
      });
  };

  /**
 * Checks if user is EMPLOYEE
 * @returns true or false
 */
  const isUserEmployee = () => {
    if (user?.Tipologia !== "EMPLOYEE") {
      return false;
    } else {
      return true;
    }
  };

  /**
   * Checks if user is ADMINISTRATOR or and EMPLOYEE
   * @returns true or false
   */
  const isUserAdmin = (section?: string) => {
    if (user?.Tipologia == "ADMINISTRATOR" || user?.Tipologia == "EMPLOYEE") {

      if (section) {
        return hasAbility(section, user?.Ruolo)
      }
      else
        return true;
    } else {
      return false;
    }
  };


  /**
   * Checks if user is ADMINISTRATOR or and EMPLOYEE
   * @returns true or false
   */
  const isUserAccounting = () => {
    if (user?.Ruolo == "ACCOUNTING") {
      return true;
    } else {
      return false;
    }
  };


  /**
   * Checks if user is CUSTOMER
   * @returns true or false
   */
  const isUserCustomer = () => {
    if (user?.Tipologia == "CUSTOMER" || user?.Tipologia == "KOLLA_CUSTOMER") {
      return true;
    } else {
      return false;
    }
  };

  /**
   * Checks if user is SUPPLIER
   * @returns true or false
   */
  const isUserSupplier = () => {
    if (user?.Tipologia == "SUPPLIER") {
      return true;
    } else {
      return false;
    }
  };

  /**
   * Checks if user is SECONDARY and is the current user
   * @returns true or false
   */
  //TODO: check on more solid variable
  const isUserSecondary = (clientId = undefined) => {
    if (user?.PrimaryUserID && !isUserAdmin()) {
      return true;
    } else {
      return false;
    }
  };

    /**
   * Checks if user is CUSTOMER
   * @returns true or false
   */
    const isUserAllowPresale = () => {
      if (user?.allowPresale == true) {
        return true;
      } else {
        return false;
      }
    };
  


  /**
   * Determines whether ability has
   * @param section 
   * @param Ruolo 
   * @returns  
   */
  function hasAbility(section: string, Ruolo: any) {
    if (!Ruolo)
      return true;

    switch (section) {
      // show document page
      case "DOCUMENTS":
        if (Ruolo == "ACCOUNTING")
          return false
        else
          return true
      // Edit user type
      case "USER_TYPE":
        if (Ruolo == "ACCOUNTING")
          return false
        else
          return false
      default:
        return false
    }
  }

  return {
    // FBuser,
    authError,
    updateLoginUserInformation,
    fb_confirmPasswordReset,
    fb_sendPasswordResetEmail,
    fb_auth,
    handleAuthStateChanged,
    invitation_signUp,
    isLoading,
    isUserAccounting,
    isUserAdmin,
    isUserAllowPresale,
    isUserCustomer,
    isUserEmployee,
    isUserSecondary,
    isUserSupplier,
    localUser,
    setUser,
    signIn,
    signOut,
    signUp,
    user,
    verifyUser,
  };
};


