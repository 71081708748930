import { CodiceEsenzioneIva } from "@/components/core/Classes/AddCustomer.classes";
import { ProductGroupClasses } from "@/components/core/Classes/ProductsGroups";
import { OrderStatus } from "@/components/core/Classes/OrderStatus";

export /**
 * Currents status
 * @param currentStatus
 * @param nextStatus
 */
  const orderStatusGuard = (currentIdStatus: string, nextIdStatus: string) => {
    const currentStatus = OrderStatus.find((status) => status.id == currentIdStatus)?.key;
    const nextStatus = OrderStatus.find((status) => status.id == nextIdStatus)?.key;

    switch (currentStatus) {
      case "PRESALE":
        if (nextStatus == "PRODUCING" || nextStatus == "CANCELLED") return true;

        return false;
      case "PRODUCING":
        if (nextStatus == "PROCESSING" || nextStatus == "CANCELLED") return true;

        return false;
      case "PROCESSING":
        if (nextStatus == "DELIVERY_READY" || nextStatus == "PICKUP_READY" || nextStatus == "CANCELLED") return true;

        return false;
      case "PICKUP_READY":
        if (nextStatus == "INVOICE_CREATED" || nextStatus == "CANCELLED") return true;

        return false;
      case "DELIVERY_READY":
        if (nextStatus == "SHIPPED" || nextStatus == "CANCELLED") return true;

        return false;
      case "SHIPPED":
        if (nextStatus == "INVOICE_CREATED") return true;

        return false;

      case "ERROR":
        return true;
      default:
        return false;
    }
  };

export /**
 * calculate discount amount
 * @param order
 * @param totalOrderAmount
 */
  const getDiscountAmount = (discount, totalOrderAmount) => {
    if (discount && Object.keys(discount).length > 0) {
      switch (discount.type) {
        case "AMOUNT":
          return Number(discount.value);
        case "PERCENTAGE":
          return Number((totalOrderAmount * discount.value) / 100);
        default:
          return 0;
      }
    } else return 0;
  };

export /**
 * calculate discount amount
 * @param discount
 * @param orderAmount
 */
  const isDiscountApplicable = (orderAmount, discount) => {
    if (orderAmount >= discount.minAmount) {
      return true;
    } else {
      return false;
    }
  };

export /**
 * get the total VAT amount based on a productListP
 * @param productsInCart
 */
  const getTotalVat = (productsInCart = [], productList = undefined) => {
    let total = productsInCart
      .map((item) => {
        // Add each value of key "quantity" to each other, then multiply this with the product price
        return item.selectedItemQuantity.reduce((a, b) => {
          // List of products is not mandatory (some productsInCart does not have product detail)
          let productInCart = productList ? productList.find((el) => el._id == item.productId) : item;
          let discountedPrice = productInCart?.Prezzo - (productInCart?.Prezzo * productInCart?.Sconto) / 100;
          if (productInCart?.Sconto) {
            let iva =
              (discountedPrice *
                ProductGroupClasses.settoreMerceologico.find((item) => {
                  return productInCart?.SettoreMerceologico == item.id;
                })?.IVA) /
              100;
            return a + b.quantity * iva;
          } else {
            let iva =
              (productInCart?.Prezzo *
                ProductGroupClasses.settoreMerceologico.find((item) => {
                  return productInCart?.SettoreMerceologico == item.id;
                })?.IVA) /
              100;
            return a + b.quantity * iva;
          }
        }, 0);
      })
      // Add each partial quantity to each other in order to calculate the total sum quantity.
      .reduce((a, b) => a + b, 0);

    // Truncate decimals
    total = truncateDecimals(total);
    return total;
  };

export /**
 * Products in cart Total Quantity
 * @param productsInCart
 */
  const getTotalQuantity = (productsInCart) => {
    return (
      productsInCart
        .map((item) => {
          // Add each value of key "quantity" to each other
          return item.selectedItemQuantity.reduce((a, b) => a + b.quantity, 0);
        })
        // Add each partial quantity to each other in order to calculate the total order quantity quantity.
        .reduce((a, b) => a + b, 0)
    );
  };

export /**
 * get the total order amount based on a productList
 * @param productsInCart
 * @param prodcutList
 * @returns
 */
  const getOrderAmount = (productsInCart, productList = undefined) => {
    let total = productsInCart
      .map((item) => {
        // Add each value of key "quantity" to each other, then multiply this with the product price
        return item.selectedItemQuantity.reduce((a, b) => {
          // List of products is not mandatory (some productsInCart does not have product detail)
          let productInCart = productList ? productList.find((el) => el._id == item.productId) : item;
          const discountedPrice = productInCart?.Prezzo - productInCart?.Prezzo * (productInCart?.Sconto / 100);
          if (productInCart?.Sconto) {
            return a + b.quantity * discountedPrice;
          } else {
            return a + b.quantity * productInCart?.Prezzo;
          }
        }, 0);
      })
      // Add each partial quantity to each other in order to calculate the total sum quantity.
      .reduce((a, b) => a + b, 0);

    // Truncate decimals
    total = truncateDecimals(total);
    return total;
  };

export /**
 * GEts partial order (leftover computation) based on the currentQuantity
 * @param codeEsenzione
 * @returns
 */
  function getPartialProductsInCart(productsInCart: any) {
  let partialOrderProductsInCart = [];

  // Calculates in every elem if currentQty is different than ordered quantity
  productsInCart.map((cartElem) => {
    let partialSelectedItemQuantity = [];
    cartElem.selectedItemQuantity.map((prod, index) => {
      const updatedQuantity = getCurrentQuantity(prod);

      // Creates the nes product
      let newProduct = { ...prod.toJSON() };
      delete newProduct._id;
      newProduct.quantity = 0;
      newProduct.supplierQuantity = undefined;
      newProduct.administratorQuantity = undefined;

      // updates quantity and save to as selectedItemQuantity
      if (prod.quantity != updatedQuantity) {
        // Sets the new products quantity
        newProduct.quantity = prod.quantity - updatedQuantity;
        partialSelectedItemQuantity.push(newProduct);
      }
    });

    // If partial selectedItemQuantity are calculated create new productsInCart and add the new partialSelectedItemQuantity
    if (partialSelectedItemQuantity.length > 0) {
      let newcartElem = { ...cartElem.toJSON() };
      newcartElem.selectedItemQuantity = partialSelectedItemQuantity;
      partialOrderProductsInCart.push(newcartElem);
    }
  });

  return partialOrderProductsInCart;
}

export /**
 * GEts if the code is Esente IVA
 * @param codeEsenzione
 * @returns
 */
  const getEsenzioneIva = (codeEsenzione) => {
    // empty code must not check
    if (codeEsenzione && codeEsenzione == "") return false;

    // retrieves the codici di esenzione anche check if codeEsenzione in included into them
    let esenzione = CodiceEsenzioneIva.find((el) => (el.key == "ART8" || el.key == "ART41") && el.cod_iva == codeEsenzione);

    if (esenzione) return true;

    return false;
  };

export /**
 * Products order group by
 * @param products
 * @param key
 * @returns
 */
  const orderGroupBy = (order, key) => {
    if (order?.length == 0) return [];

    // reduce runs this anonymous function on each element of `data` (the `item` parameter,
    // returning the `storage` parameter at the end
    return order.reduce(function (storage, item) {
      // get the first instance of the key by which we're grouping
      var group = item[key];

      // set `storage` for this instance of group to the outer scope (if not empty) or initialize it
      storage[group] = storage[group] || [];

      // add this item to its group within `storage`
      storage[group].push(item);

      // return the updated storage to the reduce function, which will then loop through the next
      return storage;
    }, {}); // {} is the initial value of the storage
  };

export /**
 * Gets current quantity
 * @param product
 * @returns
 */
  function getCurrentQuantity(product: any) {
  if (product.supplierQuantity == undefined && product.administratorQuantity == undefined) return;
  else return (product.supplierQuantity || 0) + (product.administratorQuantity || 0);
}

export /**
 * Gets current quantity
 * @param product
 * @returns
 */
  function getPositive(number: any) {
  if (number < 0) return 0;
  else return number;
}

/**
 * Gets full products amount and quantity and so on
 * @param product
 * @returns
 */
export function getProductMaths(productsInCart: any) {
  const price = productsInCart.Prezzo;

  // Total quantity of a single line (based on size and color)
  const productQuantity = productsInCart.selectedItemQuantity?.reduce((a, b) => a + b.quantity, 0);

  // Updated total quantity of a single line (based on size and color)
  const currentProductQuantity = productsInCart.selectedItemQuantity?.reduce((a, b) => a + getCurrentQuantity(b), 0);

  const currentSupplierProductQuantity = productsInCart.selectedItemQuantity?.reduce((a, b) => a + (b.supplierQuantity ?? b.quantity), 0);

  // Total price of a single line (based on size and color)
  let productAmount = productsInCart.Sconto
    ? productsInCart.selectedItemQuantity?.reduce((a, b) => a + b.quantity, 0) *
    (productsInCart.Prezzo - productsInCart.Prezzo * (productsInCart.Sconto / 100))
    : productsInCart.selectedItemQuantity?.reduce((a, b) => a + b.quantity, 0) * productsInCart.Prezzo;
  // Truncate decimals
  productAmount = truncateDecimals(productAmount);

  // Supplier total price of a single line (based on size and color)
  let supplierProductAmount = productsInCart.selectedItemQuantity?.reduce((a, b) => a + b.quantity, 0) * productsInCart.Costo;
  // Truncate decimals
  supplierProductAmount = truncateDecimals(supplierProductAmount);

  // Updated supplier total price of a single line (based on size and color)
  let currentSupplierProductAmount =
    productsInCart.selectedItemQuantity?.reduce((a, b) => a + (b.supplierQuantity ?? b.quantity), 0) * productsInCart.Costo;
  // Truncate decimals
  currentSupplierProductAmount = truncateDecimals(currentSupplierProductAmount);

  // Updated total price of a single line (based on size and color)
  let currentProductAmount = productsInCart.Sconto
    ? productsInCart.selectedItemQuantity?.reduce((a, b) => a + getCurrentQuantity(b), 0) *
    (productsInCart.Prezzo - productsInCart.Prezzo * (productsInCart.Sconto / 100))
    : productsInCart.selectedItemQuantity?.reduce((a, b) => a + getCurrentQuantity(b), 0) * productsInCart.Prezzo;
  // Truncate decimals
  currentProductAmount = truncateDecimals(currentProductAmount);

  return {
    price,
    productQuantity,
    currentProductQuantity,
    productAmount,
    currentProductAmount,
    currentSupplierProductQuantity,
    supplierProductAmount,
    currentSupplierProductAmount,
  };
}

/**
 * Gets full order amount and quantity and so on
 * CURRENT => quantity/amount is changed
 * GET_ => used when visualize order data (aggregation of data)
 * @param order
 */
export function getOrderMaths(order: any) {
  if (!order) return;

  const orderQuantity = order?.totalQuantity;
  const totalVat = truncateDecimals(order?.totalVatAmount);
  const orderAmount = truncateDecimals(order?.orderAmount);
  let shippingCost = truncateDecimals(order?.shippingCost);
  const totalOrderAmount: number = truncateDecimals(order?.totalOrderAmount);
  const cashOnDeliverySurcharge = order?.cashOnDeliverySurcharge ? truncateDecimals(order?.cashOnDeliverySurcharge) : 0;

  let currentOrderQuantity = 0;
  let currentOrderAmount = 0;
  let supplierOrderAmount = 0;
  let currentSupplierOrderAmount = 0;
  let supplierOrderQuantity = 0;
  let currentSupplierOrderQuantity = 0;
  let currentTotalVat = 0;

  let discount = getDiscountAmount(order.discount, order.totalOrderAmount);

  /*
   * CURRENT Values are used when quantity or amount is changed
   */
  order?.productsInCart?.map((item) => {
    // Updated order items quantity
    currentOrderQuantity += item.selectedItemQuantity.reduce((a, b) => a + getCurrentQuantity(b), 0);
    // Updated order items price
    if (item?.Sconto) {
      currentOrderAmount +=
        truncateDecimals(item.selectedItemQuantity.reduce((a, b) => a + getCurrentQuantity(b), 0) * (item.Prezzo - (item.Prezzo * item?.Sconto) / 100));
    } else {
      currentOrderAmount += item.selectedItemQuantity.reduce((a, b) => a + getCurrentQuantity(b), 0) * item.Prezzo;
    }

    // Check if there's a order discount and subtracts it from order amount (only if different from SHIPPING)
    if (order?.discount && order.discount.type != "SHIPPING") {
      currentOrderAmount = currentOrderAmount - discount;
    }

    // Supplier quantity
    supplierOrderQuantity += item.selectedItemQuantity.reduce((a, b) => a + b.quantity, 0);
    // Updated supplier quantity
    currentSupplierOrderQuantity += item.selectedItemQuantity.reduce((a, b) => a + getCurrentQuantity(b), 0);

    // Supplier updated order price with updated quantity with Costo instead of Prezzo
    currentSupplierOrderAmount += item.selectedItemQuantity.reduce((a, b) => a + getCurrentQuantity(b), 0) * item.Costo;
    // Supplier order price calculated with Costo instead of Prezzo
    supplierOrderAmount += item.selectedItemQuantity.reduce((a, b) => a + b.quantity, 0) * item.Costo;

    // Updated Vat if current quantity has changed
    currentTotalVat += currentOrderAmount
      ? item.selectedItemQuantity.reduce((a, b) => a + getCurrentQuantity(b), 0) *
      ((truncateDecimals(item.Prezzo - (item.Prezzo * item?.Sconto || 0) / 100) *
        ProductGroupClasses.settoreMerceologico.find((item2) => {
          return item?.SettoreMerceologico == item2.id;
        })?.IVA) /
        100)
      : 0;
  });

  // Adds the shippingCost VAT
  currentTotalVat += shippingCost != 0 ? Number(Number(shippingCost) * 0.22) : 0;
  // Add cashOnDeliverySurcharge VAT
  currentTotalVat += cashOnDeliverySurcharge != 0 && cashOnDeliverySurcharge != 0 ? Number(Number(order?.["cashOnDeliverySurcharge"]) * 0.22) : 0;

  // Truncate decimals
  currentTotalVat = truncateDecimals(currentTotalVat);

  /*
   * GET Values are used when display values (aggregates more data togheter)
   */
  let getTotalOrderAmount: number =
    order["billingData"]?.["CodiceIva"] && order["billingData"]?.["CodiceIva"] != "0"
      ? parseFloat(order?.totalOrderAmount)
      : parseFloat(order?.totalOrderAmount) + totalVat;

  // Truncate decimals
  getTotalOrderAmount = truncateDecimals(getTotalOrderAmount);

  let getCurrentTotalOrderAmount: number =
    currentOrderAmount == 0
      ? 0
      : order["billingData"]?.["CodiceIva"] && order["billingData"]?.["CodiceIva"] != "0"
        ? currentOrderAmount + shippingCost + cashOnDeliverySurcharge
        : currentOrderAmount + currentTotalVat + shippingCost + cashOnDeliverySurcharge;

  // Truncate decimals
  getCurrentTotalOrderAmount = truncateDecimals(getCurrentTotalOrderAmount);

  const getVat = truncateDecimals(order["billingData"]?.["CodiceIva"] && order["billingData"]?.["CodiceIva"] != "0" ? 0 : totalOrderAmount * 0.22);

  // Check if number are positives, return original number or 0 if negatives
  currentOrderAmount = getPositive(currentOrderAmount);
  currentSupplierOrderAmount = getPositive(currentSupplierOrderAmount);
  currentTotalVat = getPositive(currentTotalVat);
  currentSupplierOrderQuantity = getPositive(currentSupplierOrderQuantity);
  currentOrderQuantity = getPositive(currentOrderQuantity);
  getCurrentTotalOrderAmount = getPositive(getCurrentTotalOrderAmount);

  return {
    orderQuantity,
    totalVat,
    orderAmount,
    shippingCost,
    currentOrderQuantity,
    currentOrderAmount,
    supplierOrderAmount,
    currentSupplierOrderAmount,
    supplierOrderQuantity,
    currentSupplierOrderQuantity,
    currentTotalVat,
    totalOrderAmount,
    getTotalOrderAmount,
    getCurrentTotalOrderAmount,
    getVat,
  };
}

/**
 * Truncates decimals to the second decimal
 * @param number
 * @returns  xxx.xx Number
 */
export function truncateDecimals(number) {
  return Math.round(number * 1e2) / 1e2;
}

/**
 * Convert all string to number replacing , with .
 * @param string
 * @returns  xxx.xx Number
 */
export function convertToNumber(string) {
  if (string == undefined || string == "") return 0;

  return parseFloat(string.replaceAll(",", "."));
}
