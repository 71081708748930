const Listino = [
  { id: 0, name: "Listino 1" },
  { id: 1, name: "Listino 2" },
  { id: 2, name: "Listino 3" },
  { id: 3, name: "Listino 4" },
  { id: 4, name: "Listino 5" },
  { id: 5, name: "Listino 6" }
];

const Pagamento = [
  {
    id: 0,
    name: "Bonifico Bancario",
    key: "BANK_TRANSFER",
    imgSrc: "/static/checkout/bonificoBancario.png",
    width: "100",
    height: "42"
  },
  {
    id: 1,
    name: "Carta di Credito",
    key: "CREDIT_CARD",
    imgSrc: "/static/checkout/cartaDiCredito.png",
    width: "150",
    height: "45"
  },
  {
    id: 2,
    name: "Contrassegno",
    key: "CASH_ON_DELIVERY",
    imgSrc: "/static/checkout/contrassegno.png",
    width: "48",
    height: "48"
  }
];

const CodiceEsenzioneIva = [
  { id: 0, cod_iva: "13", key: "ART8", name: "Non imponibile Art.8", Iva: 0 },
  { id: 1, cod_iva: "16", key: "ART41", name: "Non imponibile Art.41 DL 331/1993", Iva: 0 },
  { id: 2, cod_iva: "0", key: "IVA22", name: "Iva 22%", Iva: 22 }
];

const StatoLogin = [
  { key: "ACTIVE", name: "Attivo" },
  { key: "LOGIN_CHECK", name: "Attivo con controllo Login" },
  { key: "TEMPORARY", name: "Temporaneo" },
  { key: "EXPIRED", name: "Scaduto" },
  { key: "DISABLED", name: "Disabilitato" }
];

const TipologiaUtente = [
  { Tipologia: "CUSTOMER", Ruolo: null, name: "Cliente" },
  { Tipologia: "SUPPLIER", Ruolo: null, name: "Fornitore" },
  { Tipologia: "ADMINISTRATOR", Ruolo: null,  name: "Amministratore" },
  { Tipologia: "EMPLOYEE", Ruolo: null, name: "Dipendente" },
  // { key: "STOREHOUSE", name: "Addetto magazzino" },
  { Tipologia: "ADMINISTRATOR", Ruolo: "ACCOUNTING", name: "Contabilità" },
  { Tipologia: "KOLLA_CUSTOMER", Ruolo: "KOLLA_CUSTOMER", name: "Cliente Kol-la" },
  { Tipologia: "KOLLA_AGENT", Ruolo: "KOLLA_AGENT", name: "Rappresentante Kol-la" },
  { Tipologia: "KOLLA_ACCOUNTING", Ruolo: "KOLLA_ACCOUNTING", name: "Contabilità Kol-la" },
  // { key: "TESTER", name: "Tester" }
];

export { Listino, Pagamento, CodiceEsenzioneIva, StatoLogin, TipologiaUtente };
