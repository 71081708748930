import ChevronWithTransition from "@/components/ui/ChevronWithTransition/ChevronWithTransition";
import { ChevronDownIcon } from "@/public/icons/icons";
import React from "react";
import Button from "react-bootstrap/Button";

type CustomDropdownToggle = {
  onClick: any;
  bootstrapStyles?: string;
  children: any;
  withChevron: boolean;
  styles?: any;
};

const CustomDropdownToggle = React.forwardRef(({ onClick, bootstrapStyles, children, styles, withChevron }: CustomDropdownToggle, ref: any) => (
  <Button
    variant="link"
    className={`d-flex ${bootstrapStyles}`}
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    style={styles}
  >
    {children}
    {withChevron && <ChevronDownIcon width="24" height="24" color="black" />}
  </Button>
));
CustomDropdownToggle.displayName = "CustomDropdownToggle";

export default CustomDropdownToggle;
