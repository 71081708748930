import React from "react";
// BOOTSTRAP
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
// STYLE
import s from "./ButtonWithSpinner.module.scss";

interface ButtonWithSpinnerProps {
  className?: string;
  divClassName?: string;
  disabled?: boolean;
  id?: string;
  isLoading: boolean;
  onClick?: any;
  variant?: string;
  style?: any;
  type?: "button" | "submit" | "reset";
  children: any;
}

const ButtonWithSpinner = ({
  divClassName = undefined,
  className = undefined,
  disabled = false,
  id = "0",
  isLoading,
  onClick = null,
  variant = "primary",
  style = {},
  type = "button",
  children,
}: ButtonWithSpinnerProps) => {
  return (
    <div className={`${divClassName}  text-truncate`}>
      <Button
        id={id}
        className={className}
        disabled={disabled}
        onClick={onClick}
        variant={variant}
        style={{ paddingTop: "0.7rem", paddingBottom: "0.7rem", position: "relative", ...style }}
        type={type}
      >
        <span className={isLoading && `${s.text} invisible`}>{children}</span>
        <span>{isLoading && <Spinner animation="border" role="status" className={s.spinner} />}</span>
      </Button>
    </div>
  );
};

export default ButtonWithSpinner;
