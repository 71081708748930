import { useEffect, useRef } from "react";
import { useRouter } from "next/router";
import { useForm, FormProvider } from "react-hook-form";
import { getInnerWindowSize, removeEmptyKeys } from "@/common/utils/sharedFunctions";
// BOOTSTRAP
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
// ICONS
import { SearchIcon, XIcon } from "@/public/icons/icons";

interface MobileSearchBarProps {
  shallow?: boolean;
  containerClassName?: string;
  defaultValue: string | string[];
  pathName: string;
  placeHolder: string;
  size?: "sm" | "lg";
  slug: boolean;
}

export default function MobileSearchBar({
  shallow,
  containerClassName,
  defaultValue,
  pathName,
  placeHolder,
  size = null,
  slug,
}: MobileSearchBarProps) {
  const router = useRouter();
  const methods = useForm();
  const { getValues, register, handleSubmit, reset } = methods;

  const onSubmitSearchField = (data) => {
    router.push(
      {
        pathname: "/" + pathName + (slug ? "/ALL_PRODUCTS" : ""),
        query: { ...removeEmptyKeys({ ...router.query, Name: data.Name, Page: 1 }) },
      },
      undefined,
      { shallow: shallow == undefined ? true : shallow }
    );
  };

  function _handleKeyDown(e) {
    if (e.key === "Enter") {
      e.target.blur(); // Hide mobile keyboard
      router.push(
        {
          pathname: "/" + pathName + (slug ? "/ALL_PRODUCTS" : ""),
          query: { ...removeEmptyKeys({ ...router.query, Name: e.target.value, Page: 1 }) },
        },
        undefined,
        { shallow: shallow == undefined ? true : shallow }
      );
      e.preventDefault();
    }
  }

  return (
    <div className={containerClassName}>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmitSearchField)} style={{ width: "100%" }}>
          <InputGroup>
            <InputGroup.Text className="" id="inputGroup-sizing-sm">
              <Button variant="link" className="p-0" type="submit" aria-label="searchbar">
                <SearchIcon height="30" width="30" color="black" />
              </Button>
            </InputGroup.Text>
            <Form.Control
              size={size}
              ref={register()}
              name="Name"
              className="fw-medium shadow-none"
              aria-label="searchbar"
              aria-describedby="searchbar"
              placeholder={placeHolder}
              defaultValue={defaultValue}
              onKeyPress={_handleKeyDown}
            />

            <InputGroup.Text className="" id="inputGroup-sizing-sm">
              <Button
                variant="link"
                className="p-0"
                onClick={() => {
                  reset({ ...getValues, Name: "" });
                  router.push({
                    pathname: "/" + pathName + (slug ? "/ALL_PRODUCTS" : ""),
                    query: { ...removeEmptyKeys({ ...router.query, Name: "", Page: 1 }) },
                  });
                }}
              >
                <XIcon height="28" width="28" color="black" />
              </Button>
            </InputGroup.Text>
          </InputGroup>
        </Form>
      </FormProvider>
    </div>
  );
}
