import { useContext, createContext, useState } from "react";
import useLocalStorage from "@/common/hooks/useLocalStorage";

// TODO: Add control key to local storage using user ID to avoid bug while logging out and logging in with another user account

// CONTEXT
const ProductCartContext = createContext(null);
const ProductCartDispatchContext = createContext(null);

// PROVIDER
export function ProductCartProvider({ children }): JSX.Element {
  const [productCart, setProductCart] = useLocalStorage("productCart", []);

  // Remove "presale" from localStorage when productCart is empty.
  if (typeof window !== "undefined" && productCart.length == 0 && localStorage.getItem("presale") != undefined) {
    localStorage.removeItem("presale");
    localStorage.removeItem("deliveryDate");
  }

  return (
    <ProductCartContext.Provider value={productCart}>
      <ProductCartDispatchContext.Provider value={setProductCart}>{children}</ProductCartDispatchContext.Provider>
    </ProductCartContext.Provider>
  );
}

function useProductCart() {
  const context = useContext(ProductCartContext);
  return context;
}

function useSetProductCart() {
  const context = useContext(ProductCartDispatchContext);
  return context;
}

export { useProductCart, useSetProductCart };
