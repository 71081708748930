import { fetcher } from "@/pages/api/util/fetcher";
import { uploadFile_s3 } from "./s3";

// const NOT_FOUND = "/placeholder/no_image.png";
const NOT_FOUND = "/static/images/product-img-placeholder.svg";

export /**
 * Get image name with webp extension
 * @param files
 */
  const getWebpImageName = (fileName) => {
    try {
      return fileName.split(".")[0] + ".webp";
    } catch (error) {
      return fileName;
    }
  };

export /**
 * Files fetch files
 * @param files
 */
  const uploadFiles = async (files) => {
    let response = { errors: [], uploaded: [] };
    for (const fl of files) {
      // Skip element if does not have a preview to update
      if (!fl.preview) continue;
      const formData = new FormData();
      formData.append("file", fl);

      // Starts the upload_image which resize and optimizes images
      await fetcher("/api/product/upload_file", {
        method: "POST",
        body: formData,
      }).then((resp) => {
        if (resp["error"]) {
          response.errors.push(resp);
        }
        else {
          response.uploaded.push(formData);
        }
      });
    }
    return response;
  };

export /**
 * Files remove files
 * @param [files]
 * @param productId
 * @returns
 */
  const removeFiles = async (files = [], productId) => {
    let errors = [];
    for (const fl of files) {
      await fetcher("/api/product/upload_file", {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          file: fl.url,
          product: productId,
        }),
      }).then((resp) => {
        if (resp["error"]) {
          errors.push(resp);
        }
      });
    }
    return errors;
  };

export /**
 * Images fetch images
 * @param images
 */
  const uploadImages = async (images) => {
    let response = { errors: [], uploaded: [] };
    return Promise.all(images.map(async (img) => {
      try {
        // Only if have a preview to update
        if (img.preview) {
          // Loads the original file on s3
          let blob = await fetch(img.preview).then((r) => r.blob());
          const image = await uploadFile_s3(blob, img.name, process.env.NEXT_PUBLIC_DO_IMGS, "image/webp");

          // Starts the upload_image which resize and optimizes images
          await fetcher("/api/product/upload_image", {
            method: "POST",
            body: JSON.stringify({ ...image?.["file"], name: img.name }),
          }).then((resp) => {
            if (resp["error"]) {
              response.errors.push(resp);
            }
            else {
              response.uploaded.push(img.name);
            }
          })
        }
      } catch (error) {
        return error;
      }
      return response;
    })
    );
  };

function sleep(seconds) {
  return new Promise<void>((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, seconds * 1000);
  });
}

export /**
 * Images remove images
 * @param [images]
 * @param productId
 * @returns
 */
  const removeImages = async (images = [], productId) => {
    let errors = [];
    for (const img of images) {
      await fetcher("/api/product/upload_image", {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          image: img.url,
          product: productId,
        }),
      }).then((resp) => {
        if (resp["error"]) {
          errors.push(resp);
        }
      });
    }
    return errors;
  };

export /**
 * Imgs url
 * @param imgUrl
 * @returns
 */
  const getMediumEDGE_Url = (imgUrl) => {
    if (!imgUrl) return NOT_FOUND;

    return process.env.NEXT_PUBLIC_DO_EDGE + "/" + process.env.NEXT_PUBLIC_DO_IMGS + "/" + process.env.NEXT_PUBLIC_DO_MEDIUM + "/" + imgUrl;
  };

export /**
 * Imgs url
 * @param imgUrl
 * @returns
 */
  const getMediumORIGIN_Url = (imgUrl) => {
    if (!imgUrl) return NOT_FOUND;

    return process.env.NEXT_PUBLIC_DO_ORIGIN + "/" + process.env.NEXT_PUBLIC_DO_IMGS + "/" + process.env.NEXT_PUBLIC_DO_MEDIUM + "/" + imgUrl;
  };

export /**
 * Imgs url
 * @param imgUrl
 * @returns
 */
  const getBigEDGE_Url = (imgUrl) => {
    if (!imgUrl) return NOT_FOUND;

    return process.env.NEXT_PUBLIC_DO_EDGE + "/" + process.env.NEXT_PUBLIC_DO_IMGS + "/" + process.env.NEXT_PUBLIC_DO_BIG + "/" + imgUrl;
  };

export /**
 * Imgs url
 * @param imgUrl
 * @returns
 */
  const getBigORIGIN_Url = (imgUrl) => {
    if (!imgUrl) return NOT_FOUND;

    return process.env.NEXT_PUBLIC_DO_ORIGIN + "/" + process.env.NEXT_PUBLIC_DO_IMGS + "/" + process.env.NEXT_PUBLIC_DO_BIG + "/" + imgUrl;
  };

export /**
 * Imgs url
 * @param imgUrl
 * @returns
 */
  const getThumbEDGE_Url = (imgUrl) => {
    if (!imgUrl) return NOT_FOUND;

    return process.env.NEXT_PUBLIC_DO_EDGE + "/" + process.env.NEXT_PUBLIC_DO_IMGS + "/" + process.env.NEXT_PUBLIC_DO_THUMB + "/" + imgUrl;
  };

export /**
 * Imgs url
 * @param imgUrl
 * @returns
 */
  const getThumbORIGIN_Url = (imgUrl) => {
    if (!imgUrl) return NOT_FOUND;

    return process.env.NEXT_PUBLIC_DO_ORIGIN + "/" + process.env.NEXT_PUBLIC_DO_IMGS + "/" + process.env.NEXT_PUBLIC_DO_THUMB + "/" + imgUrl;
  };

export /**
 * Imgs url
 * @param imgUrl
 * @returns
 */
  const getFullORIGIN_Url = (imgUrl) => {
    if (!imgUrl) return NOT_FOUND;

    return process.env.NEXT_PUBLIC_DO_ORIGIN + "/" + process.env.NEXT_PUBLIC_DO_IMGS + "/" + process.env.NEXT_PUBLIC_DO_STANDARD + "/" + imgUrl;
  };

export /**
 * Imgs url
 * @param imgUrl
 * @returns
 */
  const getFullEDGE_Url = (imgUrl) => {
    if (!imgUrl) return NOT_FOUND;

    return process.env.NEXT_PUBLIC_DO_EDGE + "/" + process.env.NEXT_PUBLIC_DO_IMGS + "/" + process.env.NEXT_PUBLIC_DO_STANDARD + "/" + imgUrl;
  };

export /**
 * Imgs url
 * @param imgUrl
 * @returns
 */
  const getORIGIN_Url = (imgUrl) => {
    if (!imgUrl) return NOT_FOUND;

    return process.env.NEXT_PUBLIC_DO_ORIGIN + "/" + process.env.NEXT_PUBLIC_DO_IMGS + "/" + imgUrl;
  };

export /**
 * Srcs get thumb fall back url
 * @param src
 * @param imgUrl
 * @returns
 */
  const getThumbFallBack_Url = (src: string, imgUrl) => {
    if (!imgUrl) return NOT_FOUND;

    if (src && src.includes(process.env.NEXT_PUBLIC_DO_STANDARD)) {
      return NOT_FOUND;
    } else if (src && src.includes("fra1.cdn.digitaloceanspaces.com")) {
      return getThumbORIGIN_Url(imgUrl);
    } else if (src && src.includes("fra1.digitaloceanspaces.com")) {
      return getFullORIGIN_Url(imgUrl);
    } else {
      return NOT_FOUND;
    }
  };

export /**
 * Srcs get thumb fall back url
 * @returns
 */
  const getNotFound_Url = () => {
    return NOT_FOUND;
  };


export /**
   * Props get default url
   * @returns  
   */
  const getDefault_Url = (images: Array<any>) => {
    if (images) {
      return (images?.find((img) => img.color == 'DEFAULT') || images[0])?.url;
    }
    else {
      return undefined;
    }

  };


