import { initializeApp, getApps, getApp, FirebaseApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// console.log("ENV ======> " + process.env.NODE_ENV + " <====== ")
// console.log("================================================")
// console.log(process)
let firebaseApp: FirebaseApp;

export const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FB_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  // databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  // storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  // messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FB_APP_ID,
  // measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

if (!getApps.length) {
  try {
    firebaseApp = initializeApp(firebaseConfig);
  } catch (error) {
    throw Error(error)
  }

}
else { 
  firebaseApp = getApp();
}

const fb_app = getApp();
const fb_auth = getAuth(firebaseApp);
export { fb_auth };
console.log(fb_app.name ? '' : 'Firebase not working :(');