import { useAuth } from "components/auth/hooks/useAuth";
import { useContext, createContext, useState, useEffect } from "react";
// CONTEXT
export const FavouritesContext = createContext(undefined);
export const FavouritesDispatchContext = createContext(undefined);

// BE
import { fetcher } from "@/pages/api/util/fetcher";


export function FavouritesProvider({ children }): JSX.Element {
  const [favourites, setFavourites] = useState([]);

  return (
    <FavouritesContext.Provider value={favourites}>
      <FavouritesDispatchContext.Provider value={setFavourites}>
        {children}
      </FavouritesDispatchContext.Provider>
    </FavouritesContext.Provider>
  );
}

function useFavourites() {
  const context = useContext(FavouritesContext)
  if (context === undefined) {
    throw new Error('useFavourites must be used within a FavouritesProvider')
  }
  return context
}

function useSetFavourites() {
  const context = useContext(FavouritesDispatchContext)
  if (context === undefined) {
    throw new Error('useSetFavourites must be used within a FavouritesProvider')
  }
  return context
}

// Initializes the favourite list related to the logged user
async function initFavouriteList() {
  const favourites = await fetcher('/api/user/favourites')
  let favs = favourites?.favourites?.[0]?.favouriteProducts;
  return ((favs) ? favs.map((favs) => { return { productCode: favs.productCode } }) : []);
}



export { useFavourites, useSetFavourites, initFavouriteList }