import { useState } from "react";
import ButtonWithSpinner from "@/components/ui/CustomButton/ButtonWithSpinner";
import { useTranslation } from "next-i18next";
// BOOTSTRAP
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

interface CustomModalProps {
  closeButton?: Boolean;
  children: any;
  backdrop?: any;
  closeFunction?: any;
  hideModal: any;
  message: String;
  message1?: String;
  message2?: String;
  onClickConfirmButton: any;
  title: String;
  withButtons: Boolean;
  withActionButton: Boolean;
  buttonQuantity: number;
  buttonText: Array<"">;
  buttonAction: any;
  showModal: any;
}

export default function CustomModal({
  closeFunction = () => null,
  closeButton = false,
  children,
  backdrop,
  hideModal,
  message,
  message1,
  message2,
  onClickConfirmButton,
  title,
  withButtons,
  withActionButton = false,
  buttonQuantity = 0,
  buttonText = [""],
  buttonAction = () => null,
  showModal,
}: CustomModalProps) {
  const { t } = useTranslation(["common"]);
  const [isLoading, setIsLoading] = useState(false);

  // transform buttonQuantity in array
  const actionQuantityArray = [...Array(buttonQuantity).keys()];

  return (
    <>
      {/* {open && (
        <ClientOnlyPortal selector="#custom-modal-portal">
          <ModalContext.Consumer>
            {(context) => ( */}
      <Modal
        onHide={hideModal}
        show={showModal}
        backdrop={backdrop || true}
        centered
        dialogClassName="custom-modal"
        aria-labelledby="alert-modal"
        className="px-0"
      >
        {closeButton ? (
          <Modal.Header
            closeButton
            onClick={() => {
              hideModal();
              closeFunction();
            }}
            className="border-0 pb-0 justify-content-center"
          ></Modal.Header>
        ) : (
          <Modal.Header className="border-0 justify-content-center"></Modal.Header>
        )}
        <span className="fs-4 fw-semibold pt-2 w-100 text-center">{title}</span>
        <Modal.Body className="px-5">
          {message && (
            <div className="text-center pb-3">
              <span>
                {message} {message1} {message2}{" "}
              </span>
            </div>
          )}
          <div className="pb-4">{children}</div>
          {withButtons && (
            <div className="mb-3 d-flex justify-content-center">
              <ButtonWithSpinner
                className="mx-2"
                variant="dark"
                isLoading={isLoading}
                disabled={isLoading}
                style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "8rem", height: "2.8rem" }}
                onClick={() => {
                  setIsLoading(true);
                  onClickConfirmButton()?.then(() => setIsLoading(false));
                }}
                type="submit"
              >
                {t("common.confirm").toUpperCase()}
              </ButtonWithSpinner>
              <Button
                className="mx-2"
                variant="dark"
                onClick={hideModal}
                style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "8rem", height: "2.8rem" }}
              >
                {t("common.cancel").toUpperCase()}
              </Button>
            </div>
          )}
          {withActionButton && (
            <div className="mb-3 d-flex justify-content-center">
              {actionQuantityArray.map((index) => (
                <Button
                  key={index}
                  className="mx-2"
                  variant="dark"
                  onClick={() => {
                    hideModal();
                    buttonAction();
                  }}
                  style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "8rem", height: "2.8rem" }}
                >
                  {buttonText[index]}
                </Button>
              ))}
            </div>
          )}
        </Modal.Body>
      </Modal>
      {/* )}
          </ModalContext.Consumer>
        </ClientOnlyPortal>
      )} */}
    </>
  );
}
