import { useEffect, useState, useRef } from "react";
import { useRouter } from "next/router";
import OutsideClick from "@/common/utils/outsideClick";
import { useTranslation } from "next-i18next";
// BOOTSTRAP
import Container from "react-bootstrap/Container";
import Collapse from "react-bootstrap/Collapse";
import Row from "react-bootstrap/Row";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
//COMPONENTS
import MenuLinks from "@/components/ui/MenuLinks/MenuLinks";
import MobileSearchBar from "@/components/ui/MobileSearchBar/MobileSearchBar";
import ServicesLinks from "@/components/ui/ServicesLinks/ServicesLinks";
import UserLinks from "@/components/ui/UserLinks/UserLinks";
import Logo from "@/components/ui/Logo/Logo";
// ICONS
import { BurgerIcon } from "@/public/icons/icons";
import ServicesLinksMobile from "@/components/ui/ServicesLinks/ServicesLinksMobile";
// STYLE
import s from "./Header.module.scss";

export default function Header() {
  const { t } = useTranslation("common");

  const router = useRouter();

  // Used to close the Navbar.Collapse in mobile when user click on a link before redirecting to that page
  const [expanded, setExpanded] = useState(false);
  const [searchbarExpanded, setSearchbarExpanded] = useState(false);

  // Track click inside/outside of navbar
  const NavbarRef = useRef(null);
  const NavbarOutsideClick = OutsideClick(NavbarRef);

  // Close navbar when clicking outside
  useEffect(() => {
    NavbarOutsideClick && setExpanded(false);
  }, [NavbarOutsideClick]);

  return (
    <>
      <Container fluid className="px-0 px-lg-3" style={{ boxShadow: expanded ? "0 3px 3px -2px gray" : "none" }}>
        {/* This specify the environment */}
        <Row className="mx-0">
          {!process.env.NEXT_PUBLIC_ENV ? "LOCAL" : process.env.NEXT_PUBLIC_ENV != "PRODUCTION" && process.env.NEXT_PUBLIC_ENV}
        </Row>

        {/* MOBILE */}
        <Navbar expand="lg" expanded={expanded} className="px-0 pt-3 d-lg-none" ref={NavbarRef}>
          <div className="d-flex">
            <Navbar.Toggle aria-controls="navbar-nav" className={s.burger} onClick={() => setExpanded(!expanded)}>
              <BurgerIcon />
            </Navbar.Toggle>
            <Logo width={53} height={45} className="ms-1" onClick={() => setExpanded(false)} href="/" clickable={"clickable"} />
          </div>
          <UserLinks setSearchbarExpanded={setSearchbarExpanded} searchbarExpanded={searchbarExpanded} />

          <div className="d-flex d-lg-none justify-content-end" style={{ width: "98%" }}>
            <Collapse in={searchbarExpanded}>
              <div style={{ width: "98%" }}>
                <MobileSearchBar
                  defaultValue={router.query["Name"] || ""}
                  pathName={"catalogue"}
                  placeHolder={t("common.search-product", { ns: "common" }) + "..."}
                  slug={true}
                />
              </div>
            </Collapse>
          </div>

          <Navbar.Collapse className="pt-1" id="navbar-nav">
            <MenuLinks setExpanded={setExpanded} />
            <Nav onSelect={() => setExpanded(false)}>
              <ServicesLinksMobile setExpanded={setExpanded} />
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>

      {/* DESKTOP */}
      <Container fluid className="d-none d-lg-block pb-4">
        <Navbar role="navigation" className="d-flex flex-column">
          <div className="d-none d-lg-flex justify-content-between p-0 pt-4 w-100">
            <ServicesLinks setExpanded={setExpanded} />
            <UserLinks searchbarExpanded={""} setSearchbarExpanded={""} />
          </div>
          <div className="justify-content-lg-center d-none d-lg-flex">
            <Logo width={223} height={80} className="" href="/" clickable="clickable" isMobile={false} />
          </div>
          <div>
            <div className={`justify-content-center align-items-end d-none py-2 d-lg-flex w-100`}>
              <MenuLinks setExpanded={setExpanded} />
            </div>
          </div>
        </Navbar>
      </Container>
    </>
  );
}
