import { truncateDecimals } from "@/common/utils/orderFunction";
import lodash from "lodash";
import { useApp } from "@/components/context/AppContext/AppContext";
import ReactDOMServer from "react-dom/server";
import * as i18nIsoCountries from "i18n-iso-countries";
i18nIsoCountries.registerLocale(require(`i18n-iso-countries/langs/en.json`));
i18nIsoCountries.registerLocale(require(`i18n-iso-countries/langs/fr.json`));
i18nIsoCountries.registerLocale(require(`i18n-iso-countries/langs/it.json`));

import { fetcher } from "@/pages/api/util/fetcher";
import differenceInDays from "date-fns/differenceInDays";

/*** RETURN THE WIDTH AND HEIGHT OF SELECTED JSX ELEMENT */
export const getReactElementSize = (elementJSX) => {
  const elementString = ReactDOMServer.renderToStaticMarkup(elementJSX);
  const elementDocument = new DOMParser().parseFromString(elementString, "text/html");
  const elementNode = elementDocument.getRootNode()["body"].firstChild;

  const container = document.createElement("table");

  container.appendChild(elementNode);
  document.body.appendChild(container);

  const width = container.clientWidth;
  const height = container.clientHeight;

  container.removeChild(elementNode);
  document.body.removeChild(container);

  return {
    width,
    height,
  };
};

/*** SORTED COUNTRY NAMES */
export const sortedCountryNames = (locale) => {
  return Object.keys(i18nIsoCountries.getNames(getLocale(locale), { select: "official" }))

    .map((key) => ({ value: patchNationsIso(key), label: i18nIsoCountries.getNames(locale, { select: "official" })[key] }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

// Patches the nationality if not correct
const patchNationsIso = (nation: string) => {
  if (nation == "CH") return "CHE";

  if (nation == "GR") return "EL";

  return nation;
};

// Patches the nationality if not correct
export const patchNationsName = (nation: string) => {
  if (nation == "CHE") return "CH";

  if (nation == "EL") return "GR";

  return nation;
};

/*** ADDRESS VALIDITY CHECK */
export const isAddressValid = (address) => {
  // Check if address exists
  if (address && !lodash.isEmpty(address)) {
    // Then check if is a "correct" address
    if (
      address.Cap != (undefined || "") &&
      address.Comune != (undefined || "") &&
      address.Nazione != (undefined || "") &&
      address.Via != (undefined || "")
    ) {
      return true;
    }
  }

  return false;
};

/*** CASH ON DELIVERY SURCHARGE CALC */
export const cashOnDeliverySurchargeCalc = (amount) => {
  let cashOnDeliverySurcharge = 0;
  if (amount < 500) {
    cashOnDeliverySurcharge = 5;
  } else {
    cashOnDeliverySurcharge = parseFloat(amount) / 100;
  }
  return truncateDecimals(cashOnDeliverySurcharge);
};

/*** RETURN NEXT URL IMAGE   */
export const nextUrlImage = (url, width, quality) => {
  return process.env.NEXT_PUBLIC_BASE_URL + "/_next/image?url=" + url + "&w=" + width + "&q=" + quality;
};

/*** CREATE BASE64 IMAGE   */

export const urlToBase64 = (urlImg) => {
  return new Promise((resolve, reject) => {
    // Initialize the XMLHttpRequest and wait until file is loaded
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      // Create a Uint8Array from ArrayBuffer
      const codes = new Uint8Array(xhr.response);

      // Get binary string from UTF-16 code units
      const bin = String.fromCharCode.apply(null, codes);

      // Convert binary to Base64
      const base64 = btoa(bin);
      resolve(base64);
    };

    // Send HTTP request and fetch file as ArrayBuffer
    xhr.open("GET", urlImg);
    xhr.responseType = "arraybuffer";
    xhr.send();
  });
};

/**
 * Catalogue category utils
 * @param category string of category
 * @returns object of category from new category key
 */
export const getCategoryName = (category: any) => {
  switch (category.key) {
    case "ALL_PRODUCTS":
      return { key: "catalogue", it: "Catalogo", en: "All Products", fr: "Catalogue" };

    case "DISCOUNT_PRODUCTS":
      return { key: "promotions", it: "Promozioni", en: "Discounts", fr: "Promotions" };

    case "NEW_PRODUCTS":
      return { key: "new-products", it: "Novità", en: "New arrivals", fr: "Nouveautés" };

    default:
      return category;
  }
};

/**
 * Reservation category utils
 * @param category string of category
 * @returns object of category from new category key
 */
export const getReservationCategoryName = (category: any) => {
  switch (category.key) {
    case "ALL_PRODUCTS":
      return { key: "reservations", it: "Preordini", en: "Reservations", fr: "Précommandes" };

    case "DISCOUNT_PRODUCTS":
      return { key: "promotions", it: "Promozioni", en: "Discounts", fr: "Promotions" };

    case "NEW_PRODUCTS":
      return { key: "new-products", it: "Novità", en: "New arrivals", fr: "Nouveautés" };

    default:
      return category;
  }
};

/**
 * Check if the object is empty
 * @param obj object to check if empty
 * @returns boolean empty object
 */
export const isEmpty = (obj: any) => {
  if (obj != undefined) {
    return Object.keys(obj).length === 0;
  } else {
    return false;
  }
};

/***FORM UTILS */
export const addZeroes = (num) => {
  const dec = num.split(".")[1];
  const len = dec && dec.length > 2 ? dec.length : 2;
  return Number(num).toFixed(len);
};

export /**
 * Keys remove empty keys
 * @param keys
 * @returns
 */
  const removeEmptyKeys = (keys) => {
    Object.keys(keys).forEach((key) => {
      if (!keys[key] || keys[key] === "") {
        delete keys[key];
      }
    });

    return keys;
  };

/***LAYOUT UTILS */

export const getWindowSize = () => {
  return {
    height: window.outerHeight,
    width: window.outerWidth,
  };
};

export const getInnerWindowSize = () => {
  return {
    height: window.innerHeight,
    width: window.innerWidth,
  };
};

export const isMobile = () => {
  const windowSize = typeof window !== "undefined" && getInnerWindowSize();
  if (windowSize.width < 991.98) {
    return true;
  } else {
    return false;
  }
};

/***USER UTILS */

export const getAccountType = async (user) => {
  if (user?.PrimaryUserID) {
    const filter: any = { _id: user?.PrimaryUserID };
    user = await fetcher("/api/user/user" + "?" + new URLSearchParams(filter).toString());
  }
  return user?.Tipologia;
};

export const getUser = async (_id) => {
  let user = {};
  if (_id) {
    const filter: any = { _id: _id };
    user = await fetcher("/api/user/user" + "?" + new URLSearchParams(filter).toString());
  }
  return user;
};

export /**
 * Users can be Expiring status starts # days before the expiration date
 * @param user => logged user
 * @returns  true if expiring, false if not expiring
 */
  const getAccountExpiration = async (user) => {
    // User is not logged or user is active (not expiring)
    if (!user) return { expiring: 0 };

    const today = new Date();

    // Gets primary user (if any)
    if (user?.PrimaryUserID) {
      const filter: any = { _id: user?.PrimaryUserID };
      user = await fetcher("/api/user/user" + "?" + new URLSearchParams(filter).toString());
    }

    // If DataScadenzaLogin is less than expiringDate, user is in expiring status
    if (user.DataScadenzaLogin != undefined && new Date(user.DataScadenzaLogin) > today) {
      return { expiring: differenceInDays(new Date(user.DataScadenzaLogin), today) + 1 };
    }

    return { expiring: 0 };
  };

export /**
 * Users label
 * @param auth
 */
  const getAccountLabel = (auth) => {
    // Secondary user must show only email
    if (auth.isUserSecondary()) {
      return auth.user?.Email;
    } else return auth.user?.RagioneSociale ? auth.user?.RagioneSociale : auth.user?.Email;
  };

export /**
 * Return locale taken from router, if not present will fallback to eng
 * @param auth
 */
  const getLocale = (locale) => {
    switch (locale) {
      case "it":
        return "it";
      case "en":
        return "en";
      case "fr":
        return "fr";

      default:
        "en";
        break;
    }
  };

export /**
 * Return locale taken from router, if not present will fallback to eng
 * @param auth
 */
  const toSentenceCase = (string) => {
    try {
      return string[0].toUpperCase() + string.slice(1).toLowerCase();
    } catch (error) { }
  };

export /**
 * Return random elements from array
 * @param arr array to get elements from
 * @param n number of elements to return
 */
  function getRandom(arr, n) {
  let result = new Array(n),
    len = arr.length,
    taken = new Array(len);
  if (n > len) throw new RangeError("getRandom: more elements taken than available");
  while (n--) {
    let x = Math.floor(Math.random() * len);
    result[n] = arr[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
}
