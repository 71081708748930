import { FC } from "react";
import NextHead from "next/head";
import { DefaultSeo } from "next-seo";
import config from "config/seo.json";

const NextSeoHead: FC = () => (
  <>
    <DefaultSeo {...config} />
    <NextHead>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="manifest" href="/site.webmanifest" key="site-manifest" />
    </NextHead>
  </>
);

export default NextSeoHead;
