import React from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
// BOOTSTRAP
import Button from "react-bootstrap/Button";
// COMPONENTS
import RecapSelectedProduct from "@/components/elements/RecapSelectedProduct/RecapSelectedProduct";
// CONTEXT
import { useProductCart } from "@/components/context/ProductCartContext/ProductCartContext";
// STYLE
import s from "./DropdownShoppingBag.module.scss";
// HOOKS
import { useProductsById } from "@/pages/api/hook/useProductsById";
import SkeletonDropdownCart from "@/components/core/Skeletons/SkeletonDropdownCart";
import { getOrderAmount, getTotalQuantity } from "@/common/utils/orderFunction";
import { ProductStatus } from "@/components/core/Classes/ProductStatus";

export default function DropdownShoppingBag({ setShoppingBagDropdown }) {
  const { t } = useTranslation(["common"]);

  const router = useRouter();

  const productCart = useProductCart();

  // Fetch products
  const { products, count, isLoading, isError } = useProductsById(productCart.map((prod) => prod.productId).toString());

  if (isLoading) return <SkeletonDropdownCart />;

  // Create a new array with only products out of catalogue
  const outOfCatalogProducts = products?.filter((product) => {
    return (
      ProductStatus.find((status) => status.key == "IN_CATALOGUE").id !== product?.["Stato"] &&
      ProductStatus.find((status) => status.key == "PRESALE").id !== product?.["Stato"]
    );
  });

  let updatedProductCart = [];
  // Create a new updated productCart, with only in catalogue products, filtering out items in outOfCatalogProducts if exists
  // const UptadatedProductCart = () => {
  if (outOfCatalogProducts == undefined) {
    updatedProductCart = productCart;
  } else
    updatedProductCart = productCart.filter((item) => {
      return outOfCatalogProducts.find((prod) => prod["_id"] == item["productId"]) ? false : true;
    });
  // };

  // Sum of all item quantity for this order, multiplied for the price of each product. The result is the total price of the current order
  // If there's a discount will be calculated in the orderAmount.
  const orderAmount = getOrderAmount(updatedProductCart, products);
  // Sum of all item quantity for this order, the result will show how many products are in the current order
  const orderTotalQuantity = getTotalQuantity(updatedProductCart);

  // Total order price, with shipping cost
  const totalOrderAmount = orderAmount;
  return (
    <div className={s.dropdown_wrapper}>
      <div className={s.product_wrapper}>
        {productCart.map((item, key) => {
          let product = products.find((selectedProduct) => selectedProduct["_id"] === item["productId"]);
          let totalQuantity = parseInt(item.selectedItemQuantity.reduce((a, b) => a + b.quantity, 0));
          let productAmount = totalQuantity * product["Prezzo"];
          // Calculate discounted amount for this product based on the presence of a discount.
          if (product?.["Sconto"]) {
            productAmount = productAmount - productAmount * (product?.["Sconto"] / 100);
          }
          return (
            <div className="pb-2" key={key} id="dropdown-shoppingBag">
              <RecapSelectedProduct
                confirmOrder={false}
                imgWidth="80%"
                paddingDescriptionCol="0"
                paddingImgCol="1"
                product={product}
                totalQuantity={totalQuantity}
                productAmount={productAmount.toFixed(2)}
              />
            </div>
          );
        })}
      </div>
      <hr />
      <div>
        <div className="d-flex justify-content-between pb-2">
          <span className="fs-7">{t("common.total-quantity")}</span>
          <span className="fs-7">{orderTotalQuantity}</span>
        </div>
        <div className="d-flex justify-content-between pb-2">
          <span className="fs-7">{t("common.order-amount")}</span>
          <span className="fs-7">{orderAmount.toFixed(2) + " €"}</span>
        </div>
      </div>
      <hr />
      <div className="py-1 d-flex justify-content-between">
        <span className="fw-semibold">{t("common.total").toUpperCase()}</span>
        <span className="fw-semibold">{totalOrderAmount.toFixed(2) + " €"}</span>
      </div>
      <div className="py-3">
        {/* <div className="pb-2 justify-content-center">
          <Button variant="light" className="w-100 fs-7py-2">
            PROCEDI ALL&apos;ACQUISTO
          </Button>
        </div> */}
        <div className="justify-content-center">
          <Button
            variant="dark"
            className="w-100 fs-7py-2"
            onClick={() => {
              router.push("/cart");
              setShoppingBagDropdown(false);
            }}
          >
            {t("common.go-to-cart").toUpperCase()}
          </Button>
        </div>
      </div>
    </div>
  );
}
