
import S3 from 'aws-sdk/clients/s3';

const space_config = {
    accessKeyId: process.env.NEXT_PUBLIC_DO_ID,
    secretAccessKey: process.env.NEXT_PUBLIC_DO_SECRET,
    region: 'fra1'  // frankfurt as default
}

const s3 = new S3({
    endpoint: 'https://' + space_config.region + '.digitaloceanspaces.com',
    accessKeyId: space_config.accessKeyId,
    secretAccessKey: space_config.secretAccessKey
});


export { s3, space_config };
