import { s3 } from '@/config/digitalOcean';
const axios = require('axios');

export const uploadFile_s3 = async (buffer, file, dir, type) => {
  let params = {
    Bucket: process.env.NEXT_PUBLIC_DO_BUCKET_NAME + (dir ? '/' + dir : ''),
    Key: file,
    ContentType: type,
    Expires: 3600,
    // this is used to set file with public url
    ACL: 'public-read',
  };

  try {
    const signedUrl = s3.getSignedUrl('putObject', params);

    return await axios({
      url: signedUrl,
      data: buffer,
      method: 'put',
      headers: {
        'Content-Type': type,
        'x-amz-acl': 'public-read',
      },
      transformRequest: [
        (data, headers) => {
          delete headers.common.Authorization;
          return data;
        },
      ],
    });

    // Save and retrieves the file data
  } catch (err) {
    return { error: err };
  }
};

export const getFile_s3 = async (file, dir) => {
  let params = {
    Bucket: process.env.NEXT_PUBLIC_DO_BUCKET_NAME + (dir ? '/' + dir : ''),
    Key: file,
  };

  try {
    // Save and retrieves the file data
    return await s3
      .getObject(params)
      .promise()
      .then((response) => {
        if (response.Body) return { buffer: response.Body };
        else return { error: response };
      });
  } catch (err) {
    return { error: err };
  }
};

export const removeFile_s3 = async (file, dir) => {
  let params = {
    Bucket: process.env.NEXT_PUBLIC_DO_BUCKET_NAME + (dir ? '/' + dir : ''),
    Key: file,
  };

  try {
    // Save and retrieves the image data
    return await s3
      .deleteObject(params)
      .promise()
      .then(async (response) => {
        return { file: [response] };
      });
  } catch (err) {
    return { error: err };
  }
};
