// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://f3185252361e417d84822e3dbd9b8d8f@o1065765.ingest.sentry.io/6057883',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,
  // ignoreErrors: [
  //   'Hydration failed because the initial UI does not match what was rendered on the server.',
  //   'Text content does not match server-rendered HTML.',
  //   'There was an error while hydrating. Because the error happened outside of a Suspense boundary, the entire root will switch to client rendering.'
  // ]
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
