import { useEffect, useState } from "react";
import { deleteCookie, hasCookie, setCookie } from "cookies-next";
import Link from "next/link";
import { useTranslation } from "next-i18next";
// BOOTSTRAP
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { ChevronDownIcon, ChevronUpIcon } from "@/public/icons/icons";

const DOMAIN = !process.env.NEXT_PUBLIC_ENV ? "localhost" : ".kesy.it"; // Cookie domain
const USER_CONSENT_COOKIE_KEY = "OnFashion.Cookie"; // Cookie name
const USER_CONSENT_COOKIE_EXPIRE_DATE = 365 * 24 * 60 * 60; // One year expiration date in seconds

export default function CookieBanner() {
  const { t } = useTranslation("common"); // Translation
  const [showBanner, setShowBanner] = useState(false);
  const [open, setOpen] = useState(false);

  const [cookieConsentIsTrue, setCookieConsentIsTrue] = useState(true);
  setTimeout(() => {
    setShowBanner(true);
  }, 2000);

  useEffect(() => {
    const consentIsTrue = hasCookie("OnFashion.Cookie");
    setCookieConsentIsTrue(consentIsTrue);
  }, []);

  /**
   * Set OnFashion.Cookie to true
   * @param value boolean
   */
  function acceptAllCookies(value: boolean) {
    try {
      setCookie(USER_CONSENT_COOKIE_KEY, value, {
        path: "/",
        sameSite: true,
        maxAge: USER_CONSENT_COOKIE_EXPIRE_DATE,
      });
      setCookieConsentIsTrue(true);
    } catch (error) {
      throw new Error();
    }
  }
  /**
   * Set OnFashion.Cookie to false
   * @param value boolean
   */
  function acceptRequiredCookies(value: boolean) {
    try {
      setCookie(USER_CONSENT_COOKIE_KEY, value, {
        path: "/",
        sameSite: true,
        maxAge: USER_CONSENT_COOKIE_EXPIRE_DATE,
      });
      setCookieConsentIsTrue(true);
      // Delete all previous set optional cookies.
      deleteCookie("_ga", { path: "/", domain: DOMAIN }); // Google Analytics
      deleteCookie("_ga_3FFMSFNWE2", { path: "/", domain: DOMAIN }); // Google Analytics
      deleteCookie("_ga_4BQWNECETS", { path: "/", domain: DOMAIN }); // Google Analytics
    } catch (error) {
      throw new Error();
    }
  }

  if (cookieConsentIsTrue || !showBanner) {
    return null;
  }

  return showBanner ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        minHeight: "5rem",
        backgroundColor: "white",
        position: "sticky",
        bottom: "0",
      }}
    >
      <div className="px-5 py-4" style={{ maxWidth: "80rem" }}>
        <div className="d-flex align-items-center mb-1">
          <h1 className="mb-0 fs-4">{t("cookie-banner.cookies-website", { ns: "common" })}</h1>
          {open ? (
            <Button variant="link" className="px-0 py-2 d-flex text-decoration-none" onClick={() => setOpen(!open)}>
              <ChevronUpIcon height="32" width="32" color="black" />
            </Button>
          ) : (
            <Button variant="link" className="px-0 py-2 d-flex text-decoration-none" onClick={() => setOpen(!open)}>
              <ChevronDownIcon height="32" width="32" color="black" />
            </Button>
          )}
        </div>
        <p className="fs-7 fw-medium">{t("cookie-banner.text-1", { ns: "common" })}</p>
        <Collapse in={open}>
          <div>
            <p className="fs-7 fw-medium">{t("cookie-banner.text-2", { ns: "common" })}</p>
            <p className="fs-7 fw-medium">
              {t("cookie-banner.text-3", { ns: "common" })}
              <Link href="/privacy-and-cookie" passHref>
                <a className="ps-1 fs-7 text-decoration-underline">{t("cookie-banner.link", { ns: "common" })}</a>
              </Link>
            </p>
          </div>
        </Collapse>
        <Row className="pt-4" style={{ display: "flex", justifyContent: "end", maxWidth: "80rem" }}>
          <Col xs={12} md={3} className="p-2">
            <Button onClick={() => acceptAllCookies(true)} className="w-100  text-nowrap py-2" variant="dark">
              <span className="fs-7 fw-bold">{t("cookie-banner.button-all-cookies", { ns: "common" }).toUpperCase()}</span>
            </Button>
          </Col>
          <Col xs={12} md={3} className="p-2">
            <Button onClick={() => acceptRequiredCookies(false)} className="w-100 text-nowrap py-2" variant="outline-dark">
              <span className="fs-7 fw-bold">{t("cookie-banner.button-required-cookies", { ns: "common" }).toUpperCase()}</span>
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  ) : null;
}
