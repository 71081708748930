import { useState } from "react";
import Link from "next/link";
import dynamic from "next/dynamic";
import { useTranslation } from "next-i18next";
import { getAccountLabel } from "@/common/utils/sharedFunctions";
import { useAuth } from "components/auth/hooks/useAuth";
// CONTEXT
import { useApp } from "@/components/context/AppContext/AppContext";
import { useModal } from "@/components/context/ModalContext";
import { useProductCart } from "@/components/context/ProductCartContext/ProductCartContext";
// COMPONENTS
import CustomDropdownToggle from "@/components/elements/AccordionsToggles/CustomDropdownToggle";
const CustomModal = dynamic(() => import("@/components/portals/CustomModal/CustomModal"), { ssr: false });
import LoginForm from "@/components/core/LoginForm/LoginFormComponent";
import SignupForm from "@/components/core/SignupForm/SignupFormComponent";
import DropdownShoppingBag from "@/components/elements/DropdownShoppingBag/DropdownShoppingBag";
// BOOTSTRAP
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// ICONS
import { HeartIcon, ShoppingbagIcon, UserIcon, SearchIcon, LogOutIcon } from "@/public/icons/icons";
// STYLE
import s from "./UserLinks.module.scss";

export default function UserLinks({ setSearchbarExpanded, searchbarExpanded }) {
  const auth = useAuth();
  const app = useApp();

  const { t } = useTranslation("header");

  // ProductCart Context
  const productCart = useProductCart();

  // Dropdowns states (close / open)
  const [userDropdownShow, setUserDropdownShow] = useState(false);
  const [shoppingBagDropdown, setShoppingBagDropdown] = useState(false);

  // Logs out the user (availbale only if auth.user is present)
  const signOutUser = () => {
    return auth.signOut().then((response) => { });
  };

  // Custom modal context state
  const { showModal } = useModal();

  // Login and Signup Modal
  const showLoginModal = () =>
    showModal(CustomModal, {
      backdrop: "static",
      children: <LoginForm />,
      closeButton: true,
    });

  const showSignupModal = () =>
    showModal(CustomModal, {
      backdrop: "static",
      children: <SignupForm />,
      closeButton: true,
    });

  // List of links are user dependent (logged in or not, admin or client, etc.)
  let userLinks;

  if (!auth.user) {
    userLinks = (
      <>
        <Navbar expanded={searchbarExpanded} expand="lg" className="p-0">
          <Button
            className="d-flex d-lg-none"
            aria-controls="searchbar-nav"
            aria-expanded={searchbarExpanded}
            variant="link"
            onClick={() => setSearchbarExpanded(!searchbarExpanded)}
          >
            <SearchIcon height="30" width="30" color="black" />
          </Button>
          <div onMouseEnter={() => setUserDropdownShow(true)} onMouseLeave={() => setUserDropdownShow(false)}>
            <Button aria-label="user-account" onClick={() => showLoginModal()} className="pe-lg-0 ps-1" variant="link">
              <UserIcon />
            </Button>
            <Dropdown
              align="end"
              id="user-dropdown"
              className="fs-7 fw-semibold d-none d-lg-inline-block"
              show={userDropdownShow}
              style={{ paddingBottom: "0.01rem" }}
            >
              <Dropdown.Toggle as={CustomDropdownToggle} bootstrapStyles="fs-7 fw-semibold" withChevron={false} id="admin-dropdown" variant="menu">
                {t("sign-in")}
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ marginTop: "0rem" }}>
                <Dropdown.Item className="small" eventKey="1">
                  <Button
                    variant="dark"
                    className="w-100 my-3"
                    onClick={() => {
                      showLoginModal();
                      setUserDropdownShow(false);
                    }}
                  >
                    {t("sign-in").toUpperCase()}
                  </Button>
                </Dropdown.Item>
                <Dropdown.Item
                  className="fs-7 fw-semibold mb-2"
                  eventKey="2"
                  onClick={() => {
                    showSignupModal();
                    setUserDropdownShow(false);
                  }}
                >
                  {t("not-registered")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Navbar>
      </>
    );
  } else {
    userLinks = (
      <Nav className={`pe-3 pe-lg-0 ${s.mobile_links}`}>
        {/* Mobile searchbar */}
        <Nav.Link className="d-flex d-lg-none">
          <Button
            aria-label="searchbar"
            aria-controls="searchbar-nav"
            aria-expanded={searchbarExpanded}
            variant="link"
            onClick={() => setSearchbarExpanded(!searchbarExpanded)}
          >
            <SearchIcon height="30" width="30" color="black" />
          </Button>
        </Nav.Link>
        {/* User */}
        <Link href="/account" passHref>
          <Nav.Link
            className="align-items-center d-flex"
            onMouseEnter={() => setUserDropdownShow(true)}
            onMouseLeave={() => setUserDropdownShow(false)}
          >
            <Dropdown show={userDropdownShow}>
              <Button aria-label="user-account" className="px-2" variant="link">
                <UserIcon />
              </Button>

              <Dropdown.Toggle variant="menu" id="user" className="px-0 fs-7 fw-semibold d-none d-lg-inline-block text-decoration-none text-truncate" style={{ maxWidth: "15rem" }}>
                <span className="text-truncate"> {getAccountLabel(auth)}</span>
              </Dropdown.Toggle>

              <div className="d-none d-lg-block">
                <Dropdown.Menu className="py-3 ms-2" style={{ marginTop: "-0.1rem" }}>
                  <>
                    {!auth.isUserEmployee() && (
                      <Link
                        href={{
                          pathname: "/user",
                          query: { ClientId: auth?.user?._id },
                        }}
                        passHref
                      >
                        <Dropdown.Item className="fw-semibold" eventKey="1">
                          {t("my-account")}
                        </Dropdown.Item>
                      </Link>
                    )}
                    {!auth.isUserSupplier() && (
                      <>
                        <Link href="/orders" passHref>
                          <Dropdown.Item className="fs-7 fw-semibold" eventKey="2">
                            {t("orders")}
                          </Dropdown.Item>
                        </Link>
                        {((auth.isUserAdmin() && app.appConfig?.showPresales?.administrator) ||
                          (auth.isUserCustomer() && app.appConfig?.showPresales?.customer && auth.isUserAllowPresale())) &&
                          <Link href="/presale-orders" passHref>
                          <Dropdown.Item className="fs-7 fw-semibold" eventKey="3">
                            {t("presale-orders")}
                          </Dropdown.Item>
                        </Link>
                        }
                                                {app.appConfig?.showReservations?.administrator && (
                          <Link href="/reservations" passHref>
                            <Dropdown.Item className="fs-7 fw-semibold" eventKey="3">
                              {t("reservations")}
                            </Dropdown.Item>
                          </Link>
                        )}
                      </>
                    )}
                    {auth.isUserSupplier() && (
                      <Link href="/orders-incoming" passHref>
                        <Dropdown.Item className="fs-7 fw-semibold" eventKey="4">
                          {t("incoming-orders")}
                        </Dropdown.Item>
                      </Link>
                    )}
                    {auth.isUserSupplier() && (
                      <Link href="/orders-completed" passHref>
                        <Dropdown.Item className="fs-7 fw-semibold" eventKey="5">
                          {t("completed-orders")}
                        </Dropdown.Item>
                      </Link>
                    )}
                    {/* {auth.isUserAdmin("DOCUMENTS") && !auth.isUserEmployee() && (
                      <Link href="/documents" passHref>
                        <Dropdown.Item className="fs-7 fw-semibold" eventKey="6">
                          {t("documents")}
                        </Dropdown.Item>
                      </Link>
                    )} */}
                    {auth.isUserAdmin() && (
                      <Link href="/refunds" passHref>
                        <Dropdown.Item className="fs-7 fw-semibold" eventKey="7">
                          {t("refunds")}
                        </Dropdown.Item>
                      </Link>
                    )}
                    {!auth.isUserEmployee() && (
                      <Link href={auth.isUserAdmin() ? "/admin-invoices" : "/invoices"} passHref>
                        <Dropdown.Item className="fs-7 fw-semibold" eventKey="8">
                          {t("invoices")}
                        </Dropdown.Item>
                      </Link>
                    )}
                    <Dropdown.Item
                      className="fs-7 fw-semibold d-flex"
                      eventKey="10"
                      onClick={() => {
                        signOutUser();
                        setUserDropdownShow(false);
                      }}
                    >
                      <span className="pe-1">
                        <LogOutIcon width="18" height="18" strokeWidth="2" />{" "}
                      </span>
                      {t("logout")}
                    </Dropdown.Item>
                  </>
                </Dropdown.Menu>
              </div>
            </Dropdown>
          </Nav.Link>
        </Link>
        <Link href="/favorites" passHref>
          <Nav.Link className="d-flex">
            <Button aria-label="favorites" className="px-2 d-flex align-items-center text-decoration-none" variant="link">
              <HeartIcon className="" />
              <span className="ps-2 fs-7 fw-semibold d-none d-lg-flex align-items-center">{t("favorites")}</span>
            </Button>
          </Nav.Link>
        </Link>
        {/* Shopping cart dropdown */}
        <Link href="/cart" passHref>
          <Nav.Link
            className="p-0 align-items-center d-flex"
            onMouseEnter={() => setShoppingBagDropdown(true)}
            onMouseLeave={() => setShoppingBagDropdown(false)}
          >
            <Dropdown id="shopping-bag-dropdown" show={shoppingBagDropdown}>
              <Button aria-label="cart" variant="link" className={s.button}>
                <ShoppingbagIcon />
                <sup className={s.sup}>
                  <Badge bg="dark" className={s.badge}>
                    {productCart.length == 0 ? "" : <span className="fs-8"> {productCart.length}</span>}
                  </Badge>
                </sup>
              </Button>
              <div className="d-none d-lg-inline-block">
                <Dropdown.Toggle variant="menu" id="shopping-bag-toggle" className={`ps-0 fs-7 fw-semibold ${s.dropdown_toggle}`}>
                  {t("cart")}
                </Dropdown.Toggle>
                {productCart.length !== 0 && (
                  <Dropdown.Menu align="end" id="shopping-bag-menu" style={{ marginTop: "-0.1rem", cursor: "default" }}>
                    <Dropdown.Item id="shopping-bag-item" className={`p-0 ${s.menu_item}`}>
                      <div className={`px-3 py-1 ${s.menu_item}`}>
                        <DropdownShoppingBag setShoppingBagDropdown={setShoppingBagDropdown} />
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                )}
              </div>
            </Dropdown>
          </Nav.Link>
        </Link>
      </Nav>
    );
  }

  return userLinks;
}
