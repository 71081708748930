import { useEffect, useState } from "react";
import { getCookie, hasCookie } from "cookies-next";
import Script from "next/script";
import { fetcher } from "./api/util/fetcher";
import NextSeoHead from "@/components/core/NextSeoHead/NextSeoHead";

// AUTH
import initAuth from "@/common/utils/initAuth";

// BOOTSTRAP
import SSRProvider from "react-bootstrap/SSRProvider";

// CONTEXT
import { AppProvider, useApp, useSetApp } from "@/components/context/AppContext/AppContext";
import { AuthProvider } from "@/components/auth/hooks/useAuth";
import { ModalProvider } from "@/components/context/ModalContext";
import { FavouritesProvider } from "@/components/context/FavouritesContext/FavouritesContext";
import { ProductCartProvider } from "@/components/context/ProductCartContext/ProductCartContext";
import Layout from "@/components/core/Layouts/Layout";

// CSS
import "@/styles/app.scss";
import "@/styles/_variables.scss";
import "bootstrap/dist/css/bootstrap.css";

// ANALYTICS
import { Analytics } from "@vercel/analytics/react";
import * as gtag from "../lib/gtag";

// I18NEXT
import { appWithTranslation } from "next-i18next";
import nextI18nConfig from "../next-i18next.config";

// NEXT
import type { AppProps } from "next/app";

// SWR
import { SWRConfig } from "swr";

// Spinner on router change
import Router from "next/router";
import NProgress from "nprogress"; //nprogress module
import "nprogress/nprogress.css"; //styles of nprogress

// TOAST
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

NProgress.configure({ showSpinner: false }); // Disabled spinner in progress bar
// Binding events.
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("hashChangeStart", () => NProgress.start());
Router.events.on("hashChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

initAuth();

const USER_CONSENT_COOKIE_KEY = "OnFashion.Cookie"; // Cookie name
const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID; // Google track id

function OnfashionEcommerceApp({ Component, pageProps, router }: AppProps): any {
  const [cookieConsent, setCookieConsent] = useState(hasCookie(USER_CONSENT_COOKIE_KEY) ? getCookie(USER_CONSENT_COOKIE_KEY) : undefined);

  // Set consent true if user has already choose "Accept all cookies".
  if (hasCookie(USER_CONSENT_COOKIE_KEY) && cookieConsent == undefined) {
    const consentIsTrue = getCookie(USER_CONSENT_COOKIE_KEY) == true;
    setCookieConsent(consentIsTrue);
  }

  // Disable or enable Google Analytics base on user choice
  useEffect(() => {
    if (!cookieConsent) {
      window[`ga-disable-${GA_TRACKING_ID}`] = true;
    } else {
      window[`ga-disable-${GA_TRACKING_ID}`] = false;
    }
  }, [cookieConsent]);

  // Handle Google Analytics events track
  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <SSRProvider>
      <SWRConfig
        value={{
          // refreshInterval: 60000,
          fetcher: fetcher,
          provider: () => new Map(),
        }}
      >
        <ProductCartProvider>
          <ModalProvider>
            <ToastContainer limit={4} position="top-right" newestOnTop={false} closeOnClick rtl={false} draggable pauseOnHover transition={Slide} />
            <Layout>
              {/* Global Site Tag (gtag.js) - Google Analytics */}
              <Script strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`} />
              <Script
                id="ga-init"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                  __html: `
                  window.dataLayer = window.dataLayer || []; 
                  function gtag(){dataLayer.push(arguments);} 

                  gtag('js', new Date()); 
                  gtag('config', '${GA_TRACKING_ID}', { 
                    page_path: window.location.pathname, 
                    ${process.env.NEXT_PUBLIC_ENV == "" ? `debug_mode: ${process.env.NEXT_PUBLIC_ENV == ""},` : ""}
                  });
                  `,
                }}
              />
              <NextSeoHead />
              <Component {...pageProps} setApp={useSetApp()} app={useApp()} />
              <Analytics />
            </Layout>
          </ModalProvider>
        </ProductCartProvider>
      </SWRConfig>
    </SSRProvider>
  );
}

// Needed to create and wrap AppWithI18n with AuthProvider in order to avoid app remount when changing route
const AppWithI18n = appWithTranslation(OnfashionEcommerceApp, nextI18nConfig);

const AppWithAuth = (props: AppProps) => (
  <FavouritesProvider>
    <AppProvider>
      <AuthProvider>
        <AppWithI18n {...props} />
      </AuthProvider>
    </AppProvider>
  </FavouritesProvider>
);

export default AppWithAuth;
