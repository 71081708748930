import { useContext, createContext, useReducer } from "react";
import { ModalRoot } from "./ModalRoot";

// CONTEXT
const ModalContext = createContext({
  component: () => <div>No modal component supplied</div>,
  modalProps: {},
  showModal: () => undefined,
  hideModal: () => undefined,
});
// export const ModalDispatchContext = createContext(undefined);
const { Provider, Consumer: ModalConsumer } = ModalContext;

const reducer = (state, { type, component, modalProps }) => {
  switch (type) {
    case "openModal":
      return { ...state, component, modalProps };
    case "hideModal":
      return { ...state, component: null, modalProps: {} };
    default:
      throw new Error("Unspecified reducer action");
  }
};

function ModalProvider({ children }): JSX.Element {
  const initialState = {
    component: null,
    modalProps: {},
    showModal: (component, modalProps = {}) => {
      dispatch({ type: "openModal", component, modalProps });
    },
    hideModal: (component, modalProps = {}) => {
      dispatch({ type: 'hideModal', component, modalProps });
    },
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <>
      <ModalContext.Provider value={state}>
        <ModalRoot />
        {children}
      </ModalContext.Provider>
    </>
  );
}

const useModal: any = () => {
  return useContext(ModalContext);
};

export { ModalProvider, ModalConsumer, useModal };
