import { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { getThumbEDGE_Url, getDefault_Url } from "common/images/utils";
import { shimmer, toBase64 } from "@/components/core/PlaceholderImages/PlaceholderImages";
import { useTranslation } from "next-i18next";
// BOOTSTRAP
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
// STYLE
import s from "./RecapSelectedProduct.module.scss";
import { useApp } from "@/components/context/AppContext/AppContext";

interface RecapSelectedProduct {
  confirmOrder: boolean;
  descriptionClass?: any;
  product: any;
  productAmount: string;
  totalQuantity: number;
  paddingDescriptionCol?: string;
  paddingImgCol?: string;
  imgWidth?: string;
}

const RecapSelectedProduct = (props: RecapSelectedProduct) => {
  const app = useApp();
  const { t } = useTranslation("common");

  const [clothingProductsGroup, setclothingProductsGroup] = useState([]);

  // Set clothingProductsGroup from app context, check if values are ready.
  useEffect(() => {
    if (app.appConfig?.clothingProductsGroup) {
      setclothingProductsGroup(app.appConfig?.clothingProductsGroup);
    }
  }, [app]);

  const productPrice = props.product["Prezzo"];
  const discountedProductPrice = productPrice - productPrice * (props.product["Sconto"] / 100);

  const OutCatalogProductMessage = () => {
    if (props.product["Stato"] == 0) {
      return (
        <span className="fs-8" style={{ color: "red" }}>
          FUORI CATALOGO
        </span>
      );
    } else if (props.product["Stato"] == 2) {
      return (
        <span className="fs-8" style={{ color: "red" }}>
          NON DISPONIBILE
        </span>
      );
    } else {
      return null;
    }
  };

  return (
    <Row>
      <Col style={{ paddingLeft: props.paddingImgCol || 0, paddingRight: 0 }} className={`${s.imageCol}`}>
        <div style={{ width: "5rem !important" }}>
          <div style={{ width: "90%" }}>
            <Link
              href={{
                pathname: "/catalogue/[category]/[product]",
                query: {
                  category: props.confirmOrder ? "" : props.product?.GruppoMerceologico[0].toLowerCase(),
                  product: props.product?._id,
                },
              }}
              passHref
            >
              <a>
                <Image
                  unoptimized
                  className={props.confirmOrder ? `${s.cursor_default}` : `${s.cursor_pointer}`}
                  alt={props.product["DescrizioneArticolo"]}
                  src={getThumbEDGE_Url(getDefault_Url(props.product.Immagini))}
                  width={60}
                  height={90}
                  layout="responsive"
                  quality={30}
                  sizes="10vw"
                  blurDataURL={`data:image/svg+xml;base64,${toBase64(shimmer(70, 105))}`}
                  placeholder="blur"
                />
              </a>
            </Link>
          </div>
        </div>
      </Col>
      {/* // TODO: pass the correct style  */}
      <Col style={{ paddingLeft: props.paddingDescriptionCol || "auto", paddingRight: 0 }} className={`fs-7 pt-1 ${props.descriptionClass}`}>
        <div className="d-flex flex-column">
          <span className={`fs-7${s.productName}`}>{props.product["DescrizioneArticolo"]}</span>
          <span className="fs-7 pb-2">{props.product["CodiceArticolo"]}</span>
          <span className="fs-7">
            {t("recap-selected-product.price")}: {props.product["Sconto"] ? discountedProductPrice.toFixed(2) + " €" : productPrice.toFixed(2) + " €"}
          </span>
          <span className="fs-7">
            {t("recap-selected-product.quantity")}: {props.totalQuantity}
          </span>
          <span className="fs-7">
            {t("recap-selected-product.total")}: {Number(props.productAmount).toFixed(2) + " €"}
          </span>
          <OutCatalogProductMessage />
        </div>
      </Col>
    </Row>
  );
};

export default RecapSelectedProduct;
