import useSWR from 'swr';
import { fetcher } from '../util/fetcher';

export function useProductsById(_ids) {
  const filter: any = { productsId: _ids }
  const { data, error, mutate } = useSWR(["/api/product/productsById" + '?' + new URLSearchParams(filter).toString()],
    fetcher, {
    revalidateOnMount: true,
  });
  return {
    products: data ? data.products : [],
    count: data ? data.count : [],
    isLoading: !error && !data,
    isError: error,
  };
}
