import React from "react";
import Link from "next/link";
import Image from "next/image";

// FIXME: The resource http://localhost:3000/_next/image?url=%2Fstatic%2Fimages%2Flogo_big.png&w=1080&q=75
// was preloaded using link preload but not used within a few seconds from the window's load event.
// Please make sure it has an appropriate `as` value and it is preloaded intentionally.
interface LogoBig {
  isMobile?: boolean;
  width?: number;
  height?: number;
  href: string;
  onClick?: any;
  clickable: string;
  className: string;
  footer?: boolean;
}

export default function Logo({ isMobile = true, width, height, href, onClick, clickable, className, footer = false }: LogoBig) {
  return (
    <Link href={href} passHref prefetch={false}>
      {footer ?
        <a style={{ position: "relative", height: isMobile ? 70 : 80, width: isMobile ? 160 : 223, cursor: "pointer" }}>
        <Image
          priority={true}
          className={`${clickable} ${className}`}
          src={isMobile ? "/static/images/mywear.png" : "/static/images/mywear.png"}
          sizes={isMobile ? "10vw" : "20vw"}
          alt="logo-kesy"
          onClick={onClick}
          layout="fill"
          unoptimized
        />
      </a>
        :
        <a style={{ position: "relative", height: isMobile ? 45 : 80, width: isMobile ? 53 : 223, cursor: "pointer" }}>
          <Image
            priority={true}
            className={`${clickable} ${className}`}
            src={isMobile ? "/static/images/logo_small.png" : "/static/images/logo_kesy_big.png"}
            sizes={isMobile ? "10vw" : "20vw"}
            alt="logo-kesy"
            onClick={onClick}
            layout="fill"
            unoptimized
          />
        </a>}
    </Link>
  );
}
