export const fetcher = (url, data = undefined) => {
  let abs_url = new URL(process.env.NEXT_PUBLIC_BASE_URL + url);
  let cookie = data?.cookies?.Token ? { cookie: "Token=" + data?.cookies?.Token } : {};

  return fetch(abs_url.toString(), {
    credentials: "include",
    ...data,
    headers: data && { ...data.headers, ...cookie },
  }).then(res => res.text())
    .then((body) => {
      try {
        return JSON.parse(body);
      } catch {
        return body;
      }
    }).catch((error) => {
      return { error: error }
    });
};
