import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import lodash from "lodash";
import { useTranslation } from "next-i18next";
import ContentLoader from "react-content-loader";
// STYLE
import s from "./Breadcrumb.module.scss";
import fallbackTranslation from "@/lib/dbTranslations";
import { useApp } from "@/components/context/AppContext/AppContext";
import { BreadcrumbJsonLd } from "next-seo";

const Skeleton = (props) => (
  <ContentLoader speed={1} width="100%" height={60} viewBox="0 0 800 100" backgroundColor="#f3f3f3" foregroundColor="#ecebeb" {...props}>
    <rect x="250" y="0" rx="3" ry="3" width="350" height="100" />
  </ContentLoader>
);

/**
 * Takes a breadcrumb title (from url path) and replaces
 * special chars to more readable chars
 *
 * @param title - The breadcrumb title
 * @returns The transformed title
 *
 */
// Split before "?Page="
const convertBreadcrumb = (title: string): string => {
  let transformedTitle = "";
  transformedTitle = title && title.split("?")[0];
  return transformedTitle;
};

interface BreadcrumbProps {
  productId?: string;
  productName?: string;
}

/**
 * A functional React component for Next.js that renders a dynamic Breadcrumb navigation
 * based on the current path within the Next.js router navigation.
 *
 *
 * @param props - object of type BreadcrumbsProps
 * @returns The breadcrumb React component.
 */
const Breadcrumb = ({ productId, productName }: BreadcrumbProps) => {
  const app = useApp();
  const router = useRouter();
  const { t } = useTranslation("common");

  const [breadcrumbs, setBreadcrumbs] = useState(undefined);

  useEffect(() => {
    const categoryObj =
      (app.appConfig?.clothingProductsGroup &&
        app.appConfig?.clothingProductsGroup.find((el) => el.key == router.query.category?.toString().toUpperCase())) ||
      router.query["category"]?.toString();

    if (router) {
      const tempLinkPath = router.asPath.split("?")[0];
      const linkPath = tempLinkPath.split("/");
      linkPath.shift();

      const pathArray = linkPath.map((path, i) => {
        if (path == "catalogue") return { breadcrumb: t("breadcrumb." + path), href: "/" + "catalogue/ALL_PRODUCTS?Page=1" };
        if (path == "presales") return { breadcrumb: t("breadcrumb." + path), href: "/" + "presales/ALL_PRODUCTS?Page=1" };
        if (path == "ALL_PRODUCTS") return { breadcrumb: "", href: "" };
        if (path == productId) return { breadcrumb: productName, href: "" };
        if (path == router.query.category)
          return {
            breadcrumb: fallbackTranslation("clothing-product-group", "db-translations", categoryObj, t, router.locale),
            href: "/" + linkPath.slice(0, i + 1).join("/"),
          };
        return {
          breadcrumb: t("breadcrumb." + path),
          href: "/" + linkPath.slice(0, i + 1).join("/"),
        };
      });

      setBreadcrumbs(pathArray);
    }
  }, [app]);

  if (!breadcrumbs) {
    return null;
  }

  return (
    <>
      {/* SEO */}
      <BreadcrumbJsonLd
        itemListElements={[
          {
            breadcrumb: "Home",
            href: "/",
          },
          ...breadcrumbs,
        ].map((bc, i) => {
          return {
            position: i + 1,
            name: bc.breadcrumb,
            item: `${process.env.NEXT_PUBLIC_BASE_URL}${bc.href}`,
          };
        })}
      />

      <nav className={s.breadcrumb} aria-label="breadcrumbs" style={{ marginBottom: "2rem", height: "2rem" }}>
        {!lodash.isEmpty(app.appConfig) ? (
          <div className={`${s.listClassName} d-flex justify-content-center align-items-baseline`}>
            <Link href="/">
              <a className={`fs-6 d-flex align-items-end ${s.activeItemClassName}`}>Home</a>
            </Link>
            {breadcrumbs.length >= 1 &&
              breadcrumbs.map((el, i) => {
                if (!el || el.breadcrumb?.length === 0) {
                  return;
                }
                return (
                  <div className="mx-0" key={el.href}>
                    <span className="px-2 fs-7">/</span>
                    <Link href={el.href}>
                      <a className={`${i === breadcrumbs?.length - 1 && `${s.inactiveItemClassName}`} fs-6`}>{convertBreadcrumb(el.breadcrumb)}</a>
                    </Link>
                  </div>
                );
              })}
          </div>
        ) : (
          <Skeleton />
        )}
      </nav>
    </>
  );
};

export default Breadcrumb;
