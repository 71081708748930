export default function detectIE() {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ");

  if (msie > 0) {
    // If Internet Explorer, return true
    return true;
  } // If another browser, return false
  else {
    return false;
  }
}
