import React, { useState } from "react";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import { useTranslation } from "next-i18next";
// CONTEXT
import { useAuth } from "@/components/auth/hooks/useAuth";
import { useModal } from "@/components/context/ModalContext";
// COMPONENTS
const CustomModal = dynamic(() => import("@/components/portals/CustomModal/CustomModal"), { ssr: false });
const SignupForm = dynamic(() => import("@/components/core/SignupForm/SignupFormComponent"), { ssr: false });
import ButtonWithSpinner from "@/components/ui/CustomButton/ButtonWithSpinner";
//BOOTSTRAP
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
//ICONS
import { EyeOffIcon, EyeIcon, WhatsAppIcon } from "@/public/icons/icons";
//STYLE
import s from "./LoginForm.module.scss";
// Sentry
import * as Sentry from "@sentry/browser";

interface LoginData {
  email: string;
  password: string;
}

export default function LoginFormComponent({ redirect = undefined }) {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClick = () => setShowPassword(!showPassword);

  const { register, errors, handleSubmit } = useForm();
  const auth = useAuth();
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { showModal, hideModal } = useModal();

  const { t } = useTranslation("common");

  const onSubmit = (data: LoginData) => {
    // Trim email to prevent "Invalid Email Errors" from Firebase. Email must not be send with whitespaces
    data.email = data.email.trim();
    setIsLoading(true);
    setError(null);
    return auth.signIn(data).then((response) => {
      setIsLoading(false);
      if (response?.error) {
        setError(response?.error);
        Sentry.captureMessage("[signIn]" + (response?.error.code || response?.error) + " on " + data.email);
      } else {
        hideModal();
        if (response.Tipologia == "SUPPLIER") router.push("home-supplier");

        redirect && router.push(redirect);
      }
    });
  };

  const onRequestSignIn = (e) => {
    e.preventDefault();
    hideModal();
    router.push({
      pathname: "/authentication",
      query: { mode: "requestSignIn" },
    });
  };

  const showSignupModal = () =>
    showModal(CustomModal, {
      backdrop: "static",
      children: <SignupForm />,
      closeButton: true,
    });

  return (
    <div>
      <div className="text-center pb-4">
        <h3 className="mb-3">{t("login-modal.login")}</h3>
        <p className="mb-4 fs-7">{t("login-modal.login-text")}</p>
      </div>
      <Form className={`${s.form_wrapper} fs-7`} onSubmit={handleSubmit(onSubmit)}>
        <div className="pb-4">
          <Form.Group className="mb-3">
            <Form.Label>{t("login-modal.email")}</Form.Label>
            <Form.Control
              name="email"
              type="text"
              isInvalid={errors?.email}
              className={`shadow-none ${s.emailform}`}
              ref={register({
                required: "auth/enter-email",
                pattern: {
                  //TODO: this regular expression must be changed with a simple one
                  value: /(?:[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                  message: "auth/invalid-email",
                },
              })}
            />
            {errors?.email && <p className="fs-8 invalid">{t("auth-errors." + errors.email.message)}</p>}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{t("login-modal.password")}</Form.Label>
            <InputGroup>
              <Form.Control
                type={showPassword ? "text" : "password"}
                className="shadow-none"
                name="password"
                isInvalid={errors?.password}
                ref={register({
                  required: "auth/enter-password",
                  minLength: {
                    value: 6,
                    message: "auth/password-length",
                  },
                })}
              />

              <InputGroup.Text className="p-0 logovariant" style={{ borderColor: errors?.password && "red" }}>
                <Button variant="link" className="shadow-none" onClick={handleClick}>
                  {showPassword ? (
                    <EyeIcon width="20" height="20" color={errors?.password ? "red" : "black"} strokeWidth="1.5" />
                  ) : (
                    <EyeOffIcon width="20" height="20" color={errors?.password ? "red" : "black"} strokeWidth="1.5" />
                  )}
                </Button>
              </InputGroup.Text>
            </InputGroup>
            {errors.password && <p className="fs-8 invalid">{t("auth-errors." + errors.password.message)}</p>}
          </Form.Group>
          <Form.Group controlId="keep-logged" className="d-flex justify-content-between">
            {/* <Form.Check className="fs-7" type="checkbox" label="Tienimi collegato" /> */}
            <Form.Text as="a" className="mt-0 fs-7 text-decoration-none" onClick={onRequestSignIn}>
              {t("login-modal.forgot-password")}
            </Form.Text>
          </Form.Group>
        </div>
        {error?.code && (
          <>
            <div className="mb-4 text-danger text-center border border-danger py-2 rounded">
              <span className="fs-7 fw-semibold" dangerouslySetInnerHTML={{ __html: t(`auth-errors.${error.code}`) }} ></span>
            </div>
            {(error?.code == "auth/missing-pair-email-vat" || error?.code == "auth/user-expired") && (
              <div className="mb-4 text-success text-center border border-light rounded">
                <div>
                  <Button
                    variant="light"
                    className={`mb-2 d-flex rounded align-items-center justify-content-center w-100 fs-7 fw-semibold ${s.text_decoration_none_hover}`}
                    onClick={() => hideModal()}
                    href={`https://wa.me/${process.env.shopInfo["Contatti"].WhatsApp}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <WhatsAppIcon width={24} height={24} color="green" strokeWidth={0.1} />
                    <span className={`ps-2  ${s.text_decoration_none_hover}`}>{t("login-modal.whatsapp")}</span>
                  </Button>
                  <Button
                    variant="light"
                    className={`w-100 fs-7 fw-semibold rounded ${s.text_decoration_none_hover}`}
                    onClick={() => hideModal()}
                    href="/contact"
                  >
                    <span className={`${s.text_decoration_none_hover}`}>{t("login-modal.customer-service")}</span>
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
        <Container className="py-2 px-0">
          <div className="py-2">
            <ButtonWithSpinner
              disabled={false}
              variant={"dark"}
              type={"submit"}
              isLoading={isLoading}
              divClassName="w-100"
              className={`p-2 ${s.button_style}`}
            >
              {t("login-modal.login").toUpperCase()}
            </ButtonWithSpinner>
          </div>
          <div className="py-2">
            <Button className={`p-2 border-dark ${s.button_style}`} style={{ height: "2.8rem" }} variant="light" onClick={showSignupModal}>
              {t("login-modal.signup").toUpperCase()}
            </Button>
          </div>
          {/* Here we could add a link towards a page for explain inscription. */}
          {/* <Row className="justify-content-center pb-4">
            <Link href="/">
              <a>Informazioni sull'iscrizione</a>
            </Link>
          </Row> */}
        </Container>
      </Form>
    </div>
  );
}
