import { useEffect, useState } from "react";
import Select from "react-select";

interface ReactSelectProps {
  closeMenuOnSelect?: boolean;
  defaultValue?: any;
  disabled?: boolean;
  refRegister?: any;
  onChange: any;
  options: any;
  getOptionLabel: any;
  getOptionValue: any;
  placeholder?: string;
  noOptionsMessage: string;
  id: string;
  invalid?: boolean;
  isActive?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
  instanceId: string;
  isSearchable?: boolean;
  value?: { value: string; label: any }[];
}

export default function ReactSelect({
  isActive = true,
  closeMenuOnSelect = false,
  defaultValue,
  disabled = false,
  refRegister = null,
  onChange,
  options,
  getOptionLabel,
  getOptionValue,
  noOptionsMessage,
  placeholder = "",
  id = "",
  invalid = false,
  instanceId = "",
  isClearable = true,
  isMulti = false,
  isSearchable = true,
  value,
}: ReactSelectProps) {
  const ReactSelectStyle = {
    indicatorSeparator: () => null,
    menu: (base) => ({
      ...base,
      zIndex: 9999,
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
    }),
    control: (base, state) => ({
      ...base,
      fontWeight: 400,
      borderRadius: 0,
      "&:hover": { borderColor: "lightgray" },
      border: "1px solid lightgray",
      boxShadow: state.isFocused ? "0 0 0 1px #A0A2A4" : "",
      "*": {
        boxShadow: "none !important",
      },
    }),
    option: (base, state) => ({
      ...base,
      // fontWeight: 400,
      paddingTop: 1,
      paddingBottom: 1,
      // backgroundColor: !("active" in state.data) ? "" : state.data.active ? "" : "#FFC107",
    }),
    multiValue: (base, state) => ({
      ...base,
      backgroundColor: !("active" in state.data) ? "#E6E6E6" : state.data.active ? "#E6E6E6" : "#FFC107",
    }),
  };

  const ReactSelectInvalidStyle = {
    indicatorSeparator: () => null,
    menu: (base) => ({
      ...base,
      zIndex: 9999,
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
    }),
    control: (base) => ({
      ...base,
      borderRadius: 0,
      borderColor: "#FF0000",
      boxShadow: "none",
    }),
    option: (base, state) => ({
      ...base,
      fontWeight: 400,
      // paddingTop: 0,
      // paddingBottom: 0,
      // backgroundColor: !("active" in state.data) ? "" : state.data.active ? "" : "#FFC107",
    }),
    multiValue: (base, state) => ({
      ...base,
      backgroundColor: !("active" in state.data) ? "#E6E6E6" : state.data.active ? "#E6E6E6" : "#FFC107",
    }),
  };

  return (
    <Select
      className="custom-select w-100 fs-6"
      classNamePrefix="select"
      closeMenuOnSelect={closeMenuOnSelect}
      disabled={disabled}
      defaultValue={defaultValue}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      isClearable={isClearable}
      id={id}
      instanceId={instanceId}
      isMulti={isMulti}
      isSearchable={isSearchable}
      noOptionsMessage={() => noOptionsMessage}
      onChange={onChange}
      options={options}
      placeholder={<div className="ps-1">{placeholder}</div>}
      ref={refRegister}
      styles={invalid ? ReactSelectInvalidStyle : ReactSelectStyle}
      value={value}
    />
  );
}
