import { getLocale, toSentenceCase } from "@/common/utils/sharedFunctions";
import { i18n } from "next-i18next";

function fallbackTranslation(type, namespace, item, t, locale, toUpperCase = false) {
  let translation = "";

  try {
    if (!item) return translation;

    // Check if translation exist, then return the translated string
    if (i18n.exists(type + "." + (item.key || item), { ns: namespace })) {
      translation = t(type + "." + (item.key || item), { ns: namespace });
    } else {
      // If no translation is found return IT translation or value (used for sizes where IT translation is not present) or key.
      if (locale) {
        translation = item[getLocale(locale)];
      }
      if (!locale && item.value) {
        translation = item.value;
      }
      if (!locale && item.key) {
        toSentenceCase(item.key);
      }
      if (!locale && typeof item === "string") {
        translation = toSentenceCase(item);
      }
    }
    return toUpperCase ? translation.toUpperCase() : translation;
  } catch (error) {
    return translation;
  }
}
export default fallbackTranslation;
