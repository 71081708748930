import { useContext, createContext, useState, useEffect } from "react";
// CONTEXT
export const AppContext = createContext(undefined);
export const AppDispatchContext = createContext(undefined);
// BE
import { fetcher } from "@/pages/api/util/fetcher";

export function AppProvider({ children }): JSX.Element {
  const [topCategories, setTopCategories] = useState([]);
  const [appConfig, setAppConfig] = useState({});

  useEffect(() => {
    initAppConfig().then((conf) => {
      setAppConfig(conf);
      initCategoriesList(conf).then((top) => {
        setTopCategories(top);
      });
    });
  }, []);

  return (
    <AppContext.Provider value={{topCategories,appConfig}}>
      <AppDispatchContext.Provider value={setAppConfig}>{children}</AppDispatchContext.Provider>
    </AppContext.Provider>
  );
}

function useApp() {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error("useApp must be used within a AppProvider");
  }
  return context;
}

function useSetApp() {
  const context = useContext(AppDispatchContext);
  if (context === undefined) {
    throw new Error("useSetApp must be used within a AppProvider");
  }
  return context;
}


async function initAppConfig() {
  const conf = await fetcher("/api/config/app");

  return conf.appConfig;
}

// Initializes the categories list related to the logged user (so far are the most sold categories)
async function initCategoriesList(appConfig) {
  if (!appConfig) return [];

  let topCaptegories = [...appConfig.clothingProductsGroup.sort((a, b) => a.it.localeCompare(b.it))];
   topCaptegories = [...topCaptegories.sort((a, b) => {
    if (b.topCategory) {
      return (a.topCategory || 5) - (b.topCategory)
    }
    else return -1;
  })];

  // Error case or empty top categories should retrieve standard list
  return topCaptegories.splice(0, 3);
}

export { useApp, useSetApp, initCategoriesList };
