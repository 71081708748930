/**
 * Retrieves the error object
 * @param response 
 * @param errorCode 
 * @returns  
 */
 export const authError = (response: any, errorCode: string) => {
	response["error"] = {};
	response["error"].code = errorCode;
	return response;
}