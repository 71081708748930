import React from "react";
import Link from "next/link";
import { useAuth } from "components/auth/hooks/useAuth";
import { useTranslation } from "next-i18next";
// BOOTSTRAP
import { Dropdown, Nav } from "react-bootstrap";
// ICONS
import { LogOutIcon, MoreHorizontalIcon } from "@/public/icons/icons";
// STYLE
import s from "./ServicesLinks.module.scss";

export default function ServicesLinks({ setExpanded }) {
  const auth = useAuth();
  const { t } = useTranslation("header");

  // Logs out the user (availbale only if auth.user is present)
  const signOutUser = () => {
    return auth.signOut();
  };

  return (
    <Nav className="align-items-start align-items-lg-center">
      <Link href="/customer-service" passHref>
        <Nav.Link className="fs-7 fw-semibold">{t("customer-service")}</Nav.Link>
      </Link>
      <Link href="/contact" passHref>
        <Nav.Link className="fs-7 fw-semibold">{t("contact")}</Nav.Link>
      </Link>
      <Nav.Link>
        <Dropdown className={s.dropdown} id="dropdown">
          <Dropdown.Toggle aria-label="services" className={`py-0 ${s.dropdown_toggle}`} variant="link" id="dropdown-toggle">
            <MoreHorizontalIcon />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Link href="/about-us" passHref>
              <Dropdown.Item className="fs-7 fw-semibold">{t("about-us")}</Dropdown.Item>
            </Link>
            <Link href="/find-us" passHref>
              <Dropdown.Item className="fs-7 fw-semibold">{t("find-us")}</Dropdown.Item>
            </Link>
          </Dropdown.Menu>
        </Dropdown>
      </Nav.Link>
    </Nav>
  );
}
